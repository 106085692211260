import styled, { css } from "styled-components";
import { SpacingValue } from "../core/spacing.styles";
import { rem, mediaUp } from "../core/styles";

const col1 = css`
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
`;

const col2 = css`
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
`;

const col3 = css`
  flex-basis: 25%;
  max-width: 25%;
`;

const col4 = css`
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
`;

const col5 = css`
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
`;

const col6 = css`
  flex-basis: 50%;
  max-width: 50%;
`;

const col7 = css`
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
`;

const col8 = css`
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
`;

const col9 = css`
  flex-basis: 75%;
  max-width: 75%;
`;

const col10 = css`
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
`;

const col11 = css`
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
`;

const col12 = css`
  flex-basis: 100%;
  max-width: 100%;
`;

const offset1 = css`
  margin-left: 8.33333333%;
`;

const offset2 = css`
  margin-left: 16.66666667%;
`;

const offset3 = css`
  margin-left: 25%;
`;

const offset4 = css`
  margin-left: 33.33333333%;
`;

const offset5 = css`
  margin-left: 41.66666667%;
`;

const offset6 = css`
  margin-left: 50%;
`;

const offset7 = css`
  margin-left: 58.33333333%;
`;

const offset8 = css`
  margin-left: 66.66666667%;
`;

const offset9 = css`
  margin-left: 75%;
`;

const offset10 = css`
  margin-left: 83.33333333%;
`;

const offset11 = css`
  margin-left: 91.66666667%;
`;
const offset12 = css`
  margin-left: 0;
`;

const offsetProportions = [
  offset1,
  offset2,
  offset3,
  offset4,
  offset5,
  offset6,
  offset7,
  offset8,
  offset9,
  offset10,
  offset11,
  offset12,
];

const proportions = [col1, col2, col3, col4, col5, col6, col7, col8, col9, col10, col11, col12];

export type ColProportion = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColProps = {
  xs?: ColProportion;
  xsOffset?: ColProportion;
  sm?: ColProportion;
  smOffset?: ColProportion;
  md?: ColProportion;
  mdOffset?: ColProportion;
  lg?: ColProportion;
  lgOffset?: ColProportion;
  xl?: ColProportion;
  xlOffset?: ColProportion;
  lastXs?: boolean;
  firstXs?: boolean;
  lastSm?: boolean;
  firstSm?: boolean;
  lastMd?: boolean;
  firstMd?: boolean;
  lastLg?: boolean;
  firstLg?: boolean;
  lastXl?: boolean;
  firstXl?: boolean;
};

export const Col = styled.div<ColProps>`
  padding-left: ${rem(12)};
  padding-right: ${rem(12)};
  box-sizing: border-box;
  ${col12}

  ${mediaUp.xs<ColProps>`
    ${({ xs }) =>
      xs &&
      `
      ${proportions[xs - 1]}
    `}

    ${({ xsOffset }) =>
      xsOffset &&
      `
      ${offsetProportions[xsOffset - 1]}
    `}
    ${({ lastXs }) =>
      lastXs &&
      `
       order: 1;
    `}
    ${({ firstXs }) =>
      firstXs &&
      `
       order: -1;
    `}
  `}

  ${mediaUp.sm<ColProps>`
    ${({ sm }) =>
      sm &&
      `
      ${proportions[sm - 1]}
    `}
    ${({ smOffset }) =>
      smOffset &&
      `
        ${offsetProportions[smOffset - 1]}
      `}
    ${({ lastSm }) =>
      lastSm &&
      `
       order: 1;
    `}
    ${({ firstSm }) =>
      firstSm &&
      `
       order: -1;
    `}
  `}
  ${mediaUp.md<ColProps>`
    ${({ md }) =>
      md &&
      `
      ${proportions[md - 1]}
    `}
    ${({ mdOffset }) =>
      mdOffset &&
      `
        ${offsetProportions[mdOffset - 1]}
      `}
    ${({ lastMd }) =>
      lastMd &&
      `
         order: 1;
      `}
    ${({ firstMd }) =>
      firstMd &&
      `
         order: -1;
      `}
  `}
  ${mediaUp.lg<ColProps>`
    ${({ lg }) =>
      lg &&
      `
      ${proportions[lg - 1]}
    `}
    ${({ lgOffset }) =>
      lgOffset &&
      `
        ${offsetProportions[lgOffset - 1]}
      `}
    ${({ lastLg }) =>
      lastLg &&
      `
         order: 1;
      `}
    ${({ firstLg }) =>
      firstLg &&
      `
         order: -1;
      `}
  `}
  ${mediaUp.xl<ColProps>`
    ${({ xl }) =>
      xl &&
      `
      ${proportions[xl - 1]}
    `}
    ${({ xlOffset }) =>
      xlOffset &&
      `
        ${offsetProportions[xlOffset - 1]}
      `}
    ${({ lastXl }) =>
      lastXl &&
      `
         order: 1;
      `}
    ${({ firstXl }) =>
      firstXl &&
      `
         order: -1;
      `}
  `}
`;

type RowProps = {
  centerXs?: boolean;
  centerSm?: boolean;
  centerMd?: boolean;
  centerLg?: boolean;
  centerXl?: boolean;
  alignXs?: string;
  alignSm?: string;
  alignMd?: string;
  alignLg?: string;
  alignXl?: string;
};
export const Row = styled.div<RowProps>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -${rem(12)};
  margin-right: -${rem(12)};

  ${mediaUp.xs<RowProps>`
    ${({ centerXs }) =>
      centerXs &&
      `
        justify-content: center;
    `}
    ${({ alignXs }) =>
      alignXs &&
      `
        align-items: ${alignXs};
    `}
  `}

  ${mediaUp.sm<RowProps>`
    ${({ centerSm }) =>
      centerSm &&
      `
        justify-content: center;
    `}
    ${({ alignSm }) =>
      alignSm &&
      `
      align-items: ${alignSm};
    `}
  `}
  ${mediaUp.md<RowProps>`
    ${({ centerMd }) =>
      centerMd &&
      `
      justify-content: center;
    `}
    ${({ alignMd }) =>
      alignMd &&
      `
      align-items: ${alignMd};
    `}
  `}
  ${mediaUp.lg<RowProps>`
    ${({ centerLg }) =>
      centerLg &&
      `
      justify-content: center;
    `}
    ${({ alignLg }) =>
      alignLg &&
      `
      align-items: ${alignLg};
    `}
  `}
  ${mediaUp.xl<RowProps>`
    ${({ centerXl }) =>
      centerXl &&
      `
      justify-content: center;
    `}
    ${({ alignXl }) =>
      alignXl &&
      `
      align-items: ${alignXl};
    `}
  `}
`;

export const Container = styled.div<{ $centered?: boolean }>`
  box-sizing: border-box;
  max-width: ${rem(1440)};
  ${({ $centered = true }) => $centered && `margin: auto;`}
  padding: 0 ${rem(24)};
  width: 100%;
`;

type FlexProps = {
  direction?: "row" | "column";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  gap?: number;
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
};
export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  align-items: ${({ alignItems = "flex-start" }) => alignItems};
  gap: ${({ gap = 8 }) => rem(gap)};
  flex-wrap: ${({ wrap = "nowrap" }) => wrap};
`;

type GridProps = {
  xsCols?: string;
  smCols?: string;
  mdCols?: string;
  lgCols?: string;
  gap?: number;
  marginBottom?: SpacingValue;
  alignItems?: "stretch" | "center" | "start" | "end" | "baseline";
};
export const Grid = styled.div<GridProps>`
  display: grid;
  gap: ${({ gap = 8 }) => rem(gap)};
  margin-bottom: ${({ marginBottom = 0 }) => rem(marginBottom)};
  align-items: ${({ alignItems = "center" }) => alignItems};

  ${mediaUp.xs<GridProps>`
    ${({ xsCols }) =>
      xsCols &&
      `
      grid-template-columns: ${xsCols};
    `}
  `}
  ${mediaUp.sm<GridProps>`
    ${({ smCols }) =>
      smCols &&
      `
      grid-template-columns: ${smCols};
    `}
  `}
  ${mediaUp.md<GridProps>`
    ${({ mdCols }) =>
      mdCols &&
      `
      grid-template-columns: ${mdCols};
    `}
  `}
  ${mediaUp.lg<GridProps>`
    ${({ lgCols }) =>
      lgCols &&
      `
      grid-template-columns: ${lgCols};
    `}
  `}
`;

type GridItemSpanProps = {
  xsSpan?: number;
  smSpan?: number;
  mdSpan?: number;
  lgSpan?: number;
  marginBottom?: SpacingValue;
};
export const GridItemSpan = styled.div<GridItemSpanProps>`
  margin-bottom: ${({ marginBottom = 0 }) => rem(marginBottom)};

  ${mediaUp.xs<GridItemSpanProps>`
    ${({ xsSpan }) =>
      xsSpan &&
      `
      grid-column-start: span ${xsSpan};
    `}
  `}
  ${mediaUp.sm<GridItemSpanProps>`
    ${({ smSpan }) =>
      smSpan &&
      `
      grid-column-start: span ${smSpan};
    `}
  `}
  ${mediaUp.md<GridItemSpanProps>`
    ${({ mdSpan }) =>
      mdSpan &&
      `
      grid-column-start: span ${mdSpan};
    `}
  `}
  ${mediaUp.lg<GridItemSpanProps>`
    ${({ lgSpan }) =>
      lgSpan &&
      `
      grid-column-start: span ${lgSpan};
    `}
  `}
`;
