import { SelectHTMLAttributes } from "react";
import { UseControllerProps, useFormContext, RegisterOptions, FieldValues } from "react-hook-form";
import { SelectInput } from "../inputs/inputs.styles";
import { Option } from "./dropdown.constants";

type Props<FormData extends FieldValues> = {
  name: UseControllerProps<FormData>["name"];
  options: Option[];
  registerOptions?: RegisterOptions<FormData>;
  isError?: boolean;
  placeholder?: string;
  noPlaceholder?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement>;

const DropDownSelect = <FormData extends FieldValues>({
  name,
  options,
  registerOptions,
  isError = false,
  placeholder = "Please select",
  noPlaceholder = false,
  ...selectProps
}: Props<FormData>) => {
  const { register } = useFormContext<FormData>();
  return (
    <SelectInput
      {...register(name, registerOptions)}
      {...selectProps}
      id={name}
      isError={isError}
      aria-label={name}
      defaultValue={selectProps.defaultValue ?? ""}
    >
      {!noPlaceholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectInput>
  );
};

export default DropDownSelect;
