import styled from "styled-components";
import { black, tan } from "@mc/components/core/colours.styles";
import { rem } from "@mc/components/core/styles";
import { ButtonPrimary } from "@mc/components/buttons";

export const RadioButton = styled.input.attrs({ type: "radio" })`
  display: none;
`;

export const Label = styled.label`
  cursor: pointer;
  display: inline-block;
  border: 2px solid transparent;
  padding: 5px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  ${RadioButton}:checked + & {
    border-color: ${black};
    background: ${tan};
    border-radius: 5px;
  }
`;

export const Image = styled.img`
  width: 120px;
  margin-right: 10px;
`;

export const ActivePortfolioButton = styled(ButtonPrimary)`
  float: right;
  margin-top: ${rem(8)};
  margin-bottom: ${rem(4)};
`;
