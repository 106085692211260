import styled from "styled-components";

import { rem } from "../core/styles";
import { green, white } from "../core/colours.styles";

export const IconSuccess = styled.div`
  display: inline-flex;
  height: ${rem(24)};
  min-width: ${rem(24)};
  line-height: ${rem(28)};
  border-radius: ${rem(24)};
  background-color: ${green};
  align-items: center;
  justify-content: center;
  margin-right: ${rem(16)};

  svg {
    fill: ${white};
  }
`;
