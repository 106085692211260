import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { generatePath, useLocation, useParams } from "react-router-dom";
import Avatar from "@mc/components/avatar/avatar";
import * as Styled from "../../../layout/navigation.styles";
import NavigationStaffProfile from "../../../layout/navigation-staff-profile";
import Logo from "../../../shared/logo/logo";
import { GET_CLIENT_NAME } from "./get-client-name.gql";
import {
  CLIENTS,
  CLIENT_PROFILE,
  GAME_PLAN,
  PREFERENCES_ONBOARDING,
  PROPERTIES,
  ACTIVE_PORTFOLIO,
  NOTIFICATIONS,
} from "../../../routes.constants";
import { ClientStatus, GetClientNameQuery, GetClientNameQueryVariables } from "@mc/common/gql/types";
import { MdArrowBack } from "react-icons/md";
import { TopBar } from "./navigation.styles";

const ClientNavigation = () => {
  const { pathname } = useLocation();
  const { clientId } = useParams<{ clientId: string }>();

  const { data } = useQuery<GetClientNameQuery, GetClientNameQueryVariables>(GET_CLIENT_NAME, {
    variables: { id: clientId! },
  });

  const foundJobFailedPaymentAttempts = data?.client.jobs.find((job) => job.failedPaymentAttempts > 0)
    ?.failedPaymentAttempts;

  const links = useMemo(() => {
    if (!data) return [];
    return [
      {
        name: "Profile",
        to: generatePath(CLIENT_PROFILE, { clientId }),
        isActive: true,
      },
      {
        name: "Preferences",
        to: generatePath(PREFERENCES_ONBOARDING, { clientId: clientId }),
        isActive: data.client.status !== ClientStatus.ACCOUNT_CREATED,
      },
      {
        name: "Game Plan",
        to: generatePath(GAME_PLAN, { clientId }),
        isActive: ![
          ClientStatus.ACCOUNT_CREATED,
          ClientStatus.ACCOUNT_VERIFIED,
          ClientStatus.ONBOARDING_IN_PROGRESS,
          ClientStatus.ONBOARDING_COMPLETE,
        ].includes(data.client.status),
      },
      {
        name: "Properties",
        to: generatePath(PROPERTIES, { clientId }),
        isActive: ![
          ClientStatus.ACCOUNT_CREATED,
          ClientStatus.ACCOUNT_VERIFIED,
          ClientStatus.ONBOARDING_IN_PROGRESS,
          ClientStatus.ONBOARDING_COMPLETE,
          ClientStatus.GAME_PLAN_OPTIONS_IN_PROGRESS,
        ].includes(data.client.status),
      },
      {
        name: "Active Portfolio",
        to: generatePath(ACTIVE_PORTFOLIO, { clientId }),
        isActive: ![
          ClientStatus.ACCOUNT_CREATED,
          ClientStatus.ACCOUNT_VERIFIED,
          ClientStatus.ONBOARDING_IN_PROGRESS,
        ].includes(data.client.status),
      },
      {
        name: "Documents",
        to: `/client/${clientId}/documents`,
        isActive: false,
      },
      {
        name: "Notifications",
        to: generatePath(NOTIFICATIONS, { clientId }),
        isActive: true,
      },
      {
        name: "Settings",
        to: `/client/${clientId}/settings`,
        isActive: false,
      },
    ];
  }, [clientId, data]);

  return (
    <>
      <Styled.Navigation aria-label="Client navigation">
        <Styled.NavigationHeader>
          <Logo />
        </Styled.NavigationHeader>

        <Styled.NavigationBack>
          <Styled.NavigationItemLink to={CLIENTS}>
            <MdArrowBack />
            Back
          </Styled.NavigationItemLink>
        </Styled.NavigationBack>

        <Styled.NavigationProfile>
          <Avatar name={data?.client?.fullName} size={32} />
          <Styled.NavigationProfileName>{data?.client?.fullName}</Styled.NavigationProfileName>
        </Styled.NavigationProfile>

        <Styled.NavigationItems>
          {links.map(({ name, to, isActive }) => (
            <Styled.NavigationItem key={name} isCurrent={pathname.includes(to)} isActive={isActive}>
              <Styled.NavigationItemLink
                to={to}
                onClick={(e) => {
                  if (!isActive) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                {name}
              </Styled.NavigationItemLink>
            </Styled.NavigationItem>
          ))}
        </Styled.NavigationItems>
        <NavigationStaffProfile />
      </Styled.Navigation>

      {foundJobFailedPaymentAttempts &&
        (foundJobFailedPaymentAttempts >= 3 ? (
          <TopBar>
            3 instalment payment attempts failed. Customer access to Purchase Tracker temporarily disabled.
          </TopBar>
        ) : foundJobFailedPaymentAttempts > 0 ? (
          <TopBar>
            Latest instalment payment failed. Customer to check their details before the next attempt in 24 hours.
          </TopBar>
        ) : null)}
    </>
  );
};

export default ClientNavigation;
