import styled from "styled-components";
import { mediaUp, rem } from "../core/styles";
import { darkGrey, black, lightGrey } from "../core/colours.styles";
import { fontSize16, fontSize14 } from "../core/typography.styles";

export type TableProps = {
  selectable?: boolean;
};
export const Table = styled.table<TableProps>`
  border-collapse: collapse;
  color: ${black};
  font-size: ${fontSize16};
  width: 100%;

  td,
  th {
    text-align: left;
    vertical-align: top;
    padding: ${rem(8)} ${rem(4)};
  }

  td {
    border-top: 1px solid ${lightGrey};
  }

  tbody tr:last-child td {
    border-bottom: 1px solid ${lightGrey};
  }

  thead th {
    color: ${darkGrey};
    font-weight: normal;
    font-size: ${fontSize14};
  }

  ${({ selectable }) =>
    selectable &&
    `
    tbody > tr:hover {
      background-color: rgba(0,0,0,0.05);
      cursor: pointer;
    }
  `}
`;

export const TableSpaced = styled.table`
  color: ${black};
  font-size: ${fontSize16};
  width: 100%;
  text-align: left;
  table-layout: fixed;

  th,
  td {
    border-top: none;
    border-bottom: 1px solid ${lightGrey};
  }

  th {
    padding: 0 0 ${rem(16)};
  }

  td {
    padding: ${rem(8)} ${rem(8)} ${rem(10)};
  }

  ${mediaUp.md`
    border-collapse: separate;
    border-spacing: ${rem(32)} 0;
  `}
`;

export const TableHorizontalScroll = styled.div`
  overflow-x: scroll;
  padding-bottom: ${rem(16)};

  ${TableSpaced} {
    min-width: ${rem(800)};
  }

  ${mediaUp.md`
    overflow-x: auto;
    padding-bottom: 0;
    margin-left: -${rem(32)};
    margin-right: -${rem(32)};
  `}
`;
