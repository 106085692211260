import styled from "styled-components";
import { rem } from "@mc/components/core/styles";
import { lightGrey } from "@mc/components/core/colours.styles";
import { Navigation } from "./navigation.styles";
import { LAYOUT_SIDE_DRAWER } from "./layout.constants";

type LayoutContentProps = {
  hasLeftSidebar?: boolean;
  hasRightSidebar?: boolean;
  $marginRight?: string | number;
};

export const LayoutContent = styled.div<LayoutContentProps>`
  margin-left: ${rem(LAYOUT_SIDE_DRAWER)};
  padding-top: ${rem(64)};
  padding-bottom: ${rem(64)};

  ${({ hasLeftSidebar }) =>
    hasLeftSidebar &&
    `
    margin-left: ${rem(LAYOUT_SIDE_DRAWER * 2)};
  `}

  ${({ hasRightSidebar, $marginRight }) =>
    hasRightSidebar &&
    `
    margin-right: ${rem($marginRight ?? 400)}
  `}
`;

type LayoutRightSidebarProps = {
  $width?: string | number;
  horizontalPadding?: string | number;
  verticalPadding?: string | number;
};

export const LayoutRightSidebar = styled.nav<LayoutRightSidebarProps>`
  border-left: 1px solid ${lightGrey};
  padding: ${({ verticalPadding = 64, horizontalPadding = 16 }) => `${rem(verticalPadding)} ${rem(horizontalPadding)}`};
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  width: ${({ $width = 400 }) => rem($width)};
`;

export const LayoutSecondaryNavigation = styled.div`
  ${Navigation} {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    left: ${rem(LAYOUT_SIDE_DRAWER)};
  }
`;
