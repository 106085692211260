import { BadgeProps } from "@mc/components/badge/badge.styles";
import {
  ActiveListingsSortOption,
  AuctionOutcome,
  BryceAmenity,
  BuyerType,
  BuyingChance,
  ClientPropertyOpinion,
  ClientStage,
  ClientStatus,
  CommuteMethod,
  ComparablePropertyRating,
  CoreLogicMarketTrendsPropertyType,
  CouponType,
  CreditCardBrand,
  DaysType,
  DepositType,
  DevelopmentApplicationAssessment,
  DevelopmentApplicationStatus,
  DiscountType,
  DomainListingPropertyType,
  DomainSoldDataSaleMethod,
  FamilyHomeType,
  FinalOutcomeStatus,
  GamePlanOptionTag,
  GamePlanPurchaseType,
  GamePlanStatus,
  IdentityType,
  InspectionIssueType,
  InternalFeature,
  JobType,
  LMH,
  LMHWithNone,
  LoanAdjustmentType,
  LoanType,
  MCService,
  Motivation,
  NeighbourOccupancyType,
  NeighbourType,
  NotificationEmailType,
  OfferStatusType,
  OfferType,
  OutsideFeature,
  PaymentMethod,
  PaymentStatus,
  PaymentTrigger,
  PostExchangeType,
  PrePurchaseReportType,
  ProfessionalServiceSource,
  ProfessionalServiceStatus,
  ProfessionalServiceType,
  ProfileStatus,
  PropertyFeature,
  PropertyShortlistReason,
  PropertyStyle,
  PropertyType,
  PurchaseStatus,
  PurchaseTrackerItemStatus,
  PurchaseTrackerStageType,
  RecurrenceFrequency,
  RenovationType,
  RepaymentType,
  SaleMethod,
  SaleOfferType,
  SaleReason,
  SchoolSector,
  SettlementStatus,
  ShortlistProgressStatus,
  ShortlistStatus,
  TypicalPropertyArchitecturalStyle,
  TypicalPropertyPeriod,
  WorkPlace,
  YourMarketComparativeReason,
  YourMarketLikedReason,
  AdditionalPurchaserRelationship,
  Tenancy,
  PropertyLeaseStatus,
  LandUsesPossibility,
  LandUsesPropertyType,
} from "../gql/types";

type MapValue = {
  value?: string;
  label: string;
  alternativeLabel?: string;
  badgeProps?: BadgeProps;
};

export const activeListingsSortingOptionMap: Record<ActiveListingsSortOption, MapValue> = {
  [ActiveListingsSortOption.LISTED_DATE_DESC]: { label: "Listing date" },
  [ActiveListingsSortOption.SUBURB_ASC]: { label: "Suburbs" },
};

export const auctionOutcomeMap: Record<AuctionOutcome, MapValue> = {
  [AuctionOutcome.MAX_OFFER_REACHED]: { label: "Maximum offer reached" },
  [AuctionOutcome.WON]: { label: "Won" },
};

export const bryceAmenityMap: Record<BryceAmenity, MapValue> = {
  [BryceAmenity.BAR]: { label: "Bar" },
  [BryceAmenity.BEACH]: { label: "Beach" },
  [BryceAmenity.CAFE]: { label: "Cafe" },
  [BryceAmenity.CHEMIST]: { label: "Chemist" },
  [BryceAmenity.CITY]: { label: "City" },
  [BryceAmenity.DOMESTIC_AIRPORT]: { label: "Domestic airport" },
  [BryceAmenity.HOSPITAL]: { label: "Hospital" },
  [BryceAmenity.MEDICAL]: { label: "Medical" },
  [BryceAmenity.NATURE]: { label: "Nature" },
  [BryceAmenity.PARK]: { label: "Park" },
  [BryceAmenity.POST_OFFICE]: { label: "Post office" },
  [BryceAmenity.PUBLIC_TRANSPORT]: { label: "Public transport" },
  [BryceAmenity.RESTAURANT]: { label: "Restaurant" },
  [BryceAmenity.SCHOOL]: { label: "School" },
  [BryceAmenity.SHOPPING_CENTRE]: { label: "Shopping centre" },
  [BryceAmenity.SUPERMARKET]: { label: "Supermarket" },
};

export const buyerTypeMap: Record<BuyerType, MapValue> = {
  [BuyerType.OWNER_OCCUPIERS]: { label: "Owner-occupiers" },
  [BuyerType.INVESTORS]: { label: "Investors" },
  [BuyerType.BUILDERS]: { label: "Developers/builders" },
  [BuyerType.DOWNSIZERS]: { label: "Downsizers" },
  [BuyerType.UPSIZERS]: { label: "Upsizers" },
  [BuyerType.FIRST_HOME_BUYERS]: { label: "First home buyers" },
  [BuyerType.FLIPPERS]: { label: "Flippers" },
};

export const buyingChanceMap: Record<BuyingChance, MapValue> = {
  [BuyingChance.VERY_WEAK]: { label: "Very weak" },
  [BuyingChance.WEAK]: { label: "Weak" },
  [BuyingChance.MODERATE]: { label: "Moderate" },
  [BuyingChance.STRONG]: { label: "Strong" },
  [BuyingChance.VERY_STRONG]: { label: "Very strong" },
  [BuyingChance.UNDISCLOSED]: { label: "Undisclosed" },
  [BuyingChance.UNKNOWN]: { label: "Unknown" },
  [BuyingChance.NONE]: { label: "None" },
};

export const clientPropertyOpinionMap: Record<ClientPropertyOpinion, MapValue> = {
  [ClientPropertyOpinion.NO_WAY]: {
    label: "No way",
    badgeProps: { colour: "red", isSecondary: false },
  },
  [ClientPropertyOpinion.LIKE_IT]: {
    label: "Like it",
    badgeProps: { colour: "green", isSecondary: true },
  },
  [ClientPropertyOpinion.LOVE_IT]: {
    label: "Love it",
    badgeProps: { colour: "green", isSecondary: false },
  },
};

export const clientStageMap: Record<ClientStage, MapValue> = {
  [ClientStage.ONBOARDING]: { label: "Onboarding" },
  [ClientStage.GAME_PLAN]: { label: "Game Plan" },
  [ClientStage.LOCATION_AND_FINANCE]: { label: "Location & Finance" },
  [ClientStage.SEARCHING]: { label: "Searching" },
  [ClientStage.IN_SETTLEMENT]: { label: "In Settlement" },
  [ClientStage.COMPLETE]: { label: "Complete" },
  [ClientStage.ON_HOLD]: { label: "On Hold" },
};

export const clientStatusMap: Record<ClientStatus, MapValue> = {
  [ClientStatus.ACCOUNT_CREATED]: {
    label: "Account created",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.ACCOUNT_VERIFIED]: {
    label: "Account verified",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.ONBOARDING_IN_PROGRESS]: {
    label: "Onboarding in progress",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.ONBOARDING_COMPLETE]: {
    label: "Onboarding complete",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.GAME_PLAN_OPTIONS_IN_PROGRESS]: {
    label: "Game Plan options in progress",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.GAME_PLAN_OPTIONS_PUBLISHED]: {
    label: "Game Plan options published",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ClientStatus.GAME_PLAN_COMPLETE]: {
    label: "Game Plan complete",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const notificationMap: Record<NotificationEmailType, MapValue> = {
  [NotificationEmailType.EMAIL_VERIFICATION]: { label: "Verify email when sign up with email and password" },
  [NotificationEmailType.WELCOME]: { label: "Email verified or sign up with 3rd party auth" },
  [NotificationEmailType.FORGOT_PASSWORD]: { label: "Forgot password" },
  [NotificationEmailType.ONBOARDING_REMINDER_1]: {
    label: "Reminder 1 email after registration and before agreement/payment (1/3/5/7/14 days)",
  },
  [NotificationEmailType.ONBOARDING_REMINDER_2]: {
    label: "Reminder 2 email after registration and before agreement/payment (21 days)",
  },
  [NotificationEmailType.ONBOARDING_FINAL_REMINDER]: {
    label: "Reminder Final email after registration and before agreement/payment (30 days)",
  },
  [NotificationEmailType.ONBOARDING_COMPLETE]: { label: "Onboarding and payment completed" },
  [NotificationEmailType.ONBOARDING_COMPLETE_FAMILY_HOME]: { label: "Onboarding and payment completed (Family Home)" },
  [NotificationEmailType.ONBOARDING_COMPLETE_FEEDBACK]: { label: "Feedback — Onboarding" },
  [NotificationEmailType.ONBOARDING_COMPLETE_OPERATION]: { label: "Onboarding and payment completed" },
  [NotificationEmailType.ONBOARDING_COMPLETE_FINANCE]: { label: "Onboarding and payment completed" },
  [NotificationEmailType.PROFESSIONAL_SERVICE_REQUEST]: { label: "Professional service team update" },
  [NotificationEmailType.PROFESSIONAL_SERVICE_INTRO]: { label: "Professional service introductions required" },
  [NotificationEmailType.ONBOARDING_CONFIRMED]: { label: "Commenced your Game Plan™" },
  [NotificationEmailType.ONBOARDING_CONFIRMED_FAMILY_HOME]: { label: "Commenced your Family Home Game Plan™" },
  [NotificationEmailType.GAME_PLAN_R1_INITIAL]: { label: "Game Plan round 1 ready" },
  [NotificationEmailType.GAME_PLAN_FAMILY_HOME_R1_INITIAL]: { label: "Game Plan round 1 ready" },
  [NotificationEmailType.GAME_PLAN_R1_REMINDER]: { label: "Game Plan round 1 reminder (3/7/14 days)" },
  [NotificationEmailType.GAME_PLAN_R1_FINAL_REMINDER]: { label: "Game Plan round 1 final reminder (21 days)" },
  [NotificationEmailType.GAME_PLAN_RX_INITIAL]: { label: "Game Plan round X ready" },
  [NotificationEmailType.GAME_PLAN_RX_REMINDER]: { label: "Game Plan round X reminder (3/7/14 days)" },
  [NotificationEmailType.GAME_PLAN_RX_FINAL_REMINDER]: { label: "Game Plan round X final reminder (21 days)" },
  [NotificationEmailType.GAME_PLAN_CONFIRMED]: { label: "Client confirms Game Plan" },
  [NotificationEmailType.GAME_PLAN_CONFIRMED_FAMILY_HOME]: { label: "Client confirms Family Home Game Plan" },
  [NotificationEmailType.BRIEF_REQUIREMENT_FAMILY_HOME_REMINDER]: { label: "Brief requirement reminder (3/7/14 days)" },
  [NotificationEmailType.BRIEF_REQUIREMENT_FAMILY_HOME_CONFIRMED]: { label: "Client confirms Family Home brief" },
  [NotificationEmailType.GAME_PLAN_CONFIRMED_FEEDBACK]: { label: "Feedback — Game Plan™" },
  [NotificationEmailType.GAME_PLAN_FEEDBACK_SUBMITTED]: { label: "Client provides Game Plan feedback or adjustments" },
  [NotificationEmailType.BRIEF_ACTIVATED_BY_ADMIN]: { label: "Activate the brief" },
  [NotificationEmailType.BRIEF_ACTIVATED_BY_ADMIN_FAMILY_HOME]: { label: "Activate the brief (Family Home)" },
  [NotificationEmailType.BRIEF_ACTIVATED_BY_CLIENT]: { label: "Clients activate the brief" },
  [NotificationEmailType.FINANCE_PRE_APPROVAL_REMINDER]: { label: "Finance pre-approval reminder (3/7/14 days)" },
  [NotificationEmailType.FINANCE_PRE_APPROVAL_FINAL_REMINDER]: { label: "Finance pre-approval reminder (21 days)" },
  [NotificationEmailType.FINANCE_PRE_APPROVAL_UPLOADED]: { label: "Client uploads finance pre-approval" },
  [NotificationEmailType.PROVIDE_IDS_REMINDER]: { label: "Provide ID's reminder (3/7/14 days)" },
  [NotificationEmailType.PROVIDE_IDS_FINAL_REMINDER]: { label: "Provide ID's reminder (21 days)" },
  [NotificationEmailType.PROVIDE_IDS_UPLOADED]: { label: "Client uploads ID" },
  [NotificationEmailType.MARKET_SUBURBS_READY]: { label: "Your markets and your suburbs published" },
  [NotificationEmailType.REVIEW_FAMILY_HOME_SUBURBS]: { label: "Review the Family Home suburbs" },
  [NotificationEmailType.FAMILY_HOME_SUBURBS_CONFIRMED]: { label: "Family Home suburbs confirmed" },
  [NotificationEmailType.FAMILY_HOME_SUBURBS_FEEDBACK_PROVIDED]: { label: "Family Home suburbs feedback provided" },
  [NotificationEmailType.MARKET_SUBURBS_READY_FEEDBACK]: { label: "Feedback — Review the brief" },
  [NotificationEmailType.SEARCH_ACTIVATED]: { label: "Your search has commenced" },
  [NotificationEmailType.SEARCH_TRACKER_UPDATE]: { label: "Search tracker update" },
  [NotificationEmailType.PROPERTY_SHORTLISTED]: { label: "Property short listed" },
  [NotificationEmailType.PROPERTY_TOP_RESULT]: { label: "Property top result" },
  [NotificationEmailType.PURCHASE_TRACKER_STAGE_2_PUBLISHED]: { label: "Purchase Tracker stage 2 published" },
  [NotificationEmailType.PURCHASE_TRACKER_STAGE_2_APPROVED]: { label: "Purchase Tracker stage 2 approved" },
  [NotificationEmailType.PURCHASE_TRACKER_STAGE_3_PUBLISHED]: { label: "Purchase Tracker stage 3 published" },
  [NotificationEmailType.PURCHASE_TRACKER_STAGE_3_APPROVED]: { label: "Purchase Tracker stage 3 approved" },
  [NotificationEmailType.FINAL_OUTCOME_CONDITIONAL_PUBLISHED]: { label: "Final outcome published (conditional)" },
  [NotificationEmailType.FINAL_OUTCOME_UNCONDITIONAL_PUBLISHED]: { label: "Final outcome published (unconditional)" },
  [NotificationEmailType.PAY_DEPOSIT_RECEIPT_UPLOADED]: { label: "Pay Deposit receipt uploaded" },
  [NotificationEmailType.FINANCE_APPROVAL_UPLOADED]: { label: "Finance Approval uploaded" },
  [NotificationEmailType.PRE_SETTLEMENT_INSPECTION_REMINDER]: { label: "Pre-settlement inspection reminder" },
  [NotificationEmailType.PRE_SETTLEMENT_INSPECTION_READY]: { label: "Pre-settlement inspection ready" },
  [NotificationEmailType.SETTLEMENT_APPROVED_BY_CLIENT]: { label: "Funds to purchase confirmed" },
  [NotificationEmailType.FINAL_SETTLEMENT_STATUS]: { label: "Final settlement status" },
  [NotificationEmailType.FAILED_PAYMENT_1]: { label: "Failed payment 1" },
  [NotificationEmailType.FAILED_PAYMENT_2]: { label: "Failed payment 2" },
  [NotificationEmailType.FAILED_PAYMENT_3]: { label: "Failed payment 3" },
};

export const commuteMethodMap: Record<CommuteMethod, MapValue> = {
  [CommuteMethod.DRIVE]: { label: "Drive" },
  [CommuteMethod.WALK]: { label: "Walk" },
  [CommuteMethod.PUBLIC_TRANSPORT]: { label: "Public transport" },
  [CommuteMethod.CYCLE]: { label: "Cycle" },
};

export const comparablePropertyRatingMap: Record<ComparablePropertyRating, MapValue> = {
  [ComparablePropertyRating.INFERIOR]: { label: "Inferior" },
  [ComparablePropertyRating.INFERIOR_EQUAL]: { label: "Inferior / Equal" },
  [ComparablePropertyRating.EQUAL_INFERIOR]: { label: "Equal / Inferior" },
  [ComparablePropertyRating.EQUAL]: { label: "Equal" },
  [ComparablePropertyRating.EQUAL_SUPERIOR]: { label: "Equal / Superior" },
  [ComparablePropertyRating.SUPERIOR_EQUAL]: { label: "Superior / Equal" },
  [ComparablePropertyRating.SUPERIOR]: { label: "Superior" },
};

export const coreLogicMarketTrendsPropertyTypeMap: Record<CoreLogicMarketTrendsPropertyType, MapValue> = {
  [CoreLogicMarketTrendsPropertyType.HOUSE]: { label: "House" },
  [CoreLogicMarketTrendsPropertyType.UNIT]: { label: "Unit" },
};

export const couponTypeMap: Record<CouponType, MapValue> = {
  [CouponType.DOLLAR]: { label: "Dollar" },
  [CouponType.PERCENT]: { label: "Percentage" },
  [CouponType.OVERRIDE]: { label: "Override" },
};

export const creditCardBrandMap: Record<CreditCardBrand, MapValue> = {
  [CreditCardBrand.AMEX]: { label: "AMEX" },
  [CreditCardBrand.DINERS]: { label: "Diners" },
  [CreditCardBrand.DISCOVER]: { label: "Discover" },
  [CreditCardBrand.EFTPOS_AU]: { label: "EFTPOS AU" },
  [CreditCardBrand.JCB]: { label: "JCB" },
  [CreditCardBrand.MASTERCARD]: { label: "Mastercard" },
  [CreditCardBrand.UNIONPAY]: { label: "UnionPay" },
  [CreditCardBrand.VISA]: { label: "Visa" },
  [CreditCardBrand.UNKNOWN]: { label: "Unknown" },
};

export const daysTypeMap: Record<DaysType, MapValue> = {
  [DaysType.BUSINESS_DAYS]: { label: "Business days" },
  [DaysType.CALENDAR_DAYS]: { label: "Calendar days" },
};

export const depositTypeMap: Record<DepositType, MapValue> = {
  [DepositType.DEFT]: { label: "DEFT Auction Pay" },
  [DepositType.MANUAL]: { label: "Manual" },
};

export const developmentApplicationAssessmentMap: Record<DevelopmentApplicationAssessment, MapValue> = {
  [DevelopmentApplicationAssessment.MAJOR_CONCERN]: { label: "Major concern" },
  [DevelopmentApplicationAssessment.MINOR_CONCERN]: { label: "Minor concern" },
  [DevelopmentApplicationAssessment.NO_CONCERN]: { label: "No concern" },
};

export const developmentApplicationStatusMap: Record<DevelopmentApplicationStatus, MapValue> = {
  [DevelopmentApplicationStatus.APPROVED]: { label: "Approved" },
  [DevelopmentApplicationStatus.REJECTED]: { label: "Rejected" },
  [DevelopmentApplicationStatus.PERMIT_GRANTED]: { label: "Permit granted" },
  [DevelopmentApplicationStatus.DETERMINATION_PENDING]: { label: "Determination pending" },
};

export const discountTypeMap: Record<DiscountType, MapValue> = {
  [DiscountType.PERCENT]: { label: "Percentage" },
  [DiscountType.DOLLAR]: { label: "Dollar" },
};

export const domainListingPropertyTypeMap: Record<DomainListingPropertyType, MapValue> = {
  [DomainListingPropertyType.ACREAGE_SEMI_RURAL]: { label: "Acreage semi-rural" },
  [DomainListingPropertyType.APARTMENT_UNIT_FLAT]: { label: "Apartment / unit / flat" },
  [DomainListingPropertyType.AQUACULTURE]: { label: "Aquaculture" },
  [DomainListingPropertyType.BLOCK_OF_UNITS]: { label: "Block of units" },
  [DomainListingPropertyType.CAR_SPACE]: { label: "Car space" },
  [DomainListingPropertyType.CROPPING]: { label: "Cropping" },
  [DomainListingPropertyType.DAIRY_FARMING]: { label: "Dairy farming" },
  [DomainListingPropertyType.DEVELOPMENT_SITE]: { label: "Development site" },
  [DomainListingPropertyType.DUPLEX]: { label: "Duplex" },
  [DomainListingPropertyType.EQUINE]: { label: "Equine" },
  [DomainListingPropertyType.FARM]: { label: "Farm" },
  [DomainListingPropertyType.FARMLET]: { label: "Farmlet" },
  [DomainListingPropertyType.FISHING_FORESTRY]: { label: "Fishing / forestry" },
  [DomainListingPropertyType.GRAZING]: { label: "Grazing" },
  [DomainListingPropertyType.HORTICULTURE]: { label: "Horticulture" },
  [DomainListingPropertyType.HOUSE]: { label: "House" },
  [DomainListingPropertyType.IRRIGATION_SERVICES]: { label: "Irrigation services" },
  [DomainListingPropertyType.LIVESTOCK]: { label: "Livestock" },
  [DomainListingPropertyType.MIXED_FARMING]: { label: "Mixed farming" },
  [DomainListingPropertyType.NEW_APARTMENTS]: { label: "New apartments" },
  [DomainListingPropertyType.NEW_HOME_DESIGNS]: { label: "New home designs" },
  [DomainListingPropertyType.NEW_HOUSE_LAND]: { label: "New house and land" },
  [DomainListingPropertyType.NEW_LAND]: { label: "New land" },
  [DomainListingPropertyType.ORCHARD]: { label: "Orchard" },
  [DomainListingPropertyType.PENTHOUSE]: { label: "Penthouse" },
  [DomainListingPropertyType.RETIREMENT_VILLAGE]: { label: "Retirement village" },
  [DomainListingPropertyType.RURAL]: { label: "Rural" },
  [DomainListingPropertyType.RURAL_LIFESTYLE]: { label: "Rural lifestyle" },
  [DomainListingPropertyType.SEMI_DETACHED]: { label: "Semi-detached" },
  [DomainListingPropertyType.SPECIALIST_FARM]: { label: "Specialist farm" },
  [DomainListingPropertyType.STUDIO]: { label: "Studio" },
  [DomainListingPropertyType.TERRACE]: { label: "Terrace" },
  [DomainListingPropertyType.TOWNHOUSE]: { label: "Townhouse" },
  [DomainListingPropertyType.UNKNOWN]: { label: "Unknown" },
  [DomainListingPropertyType.VACANT_LAND]: { label: "Vacant land" },
  [DomainListingPropertyType.VILLA]: { label: "Villa" },
  [DomainListingPropertyType.VITICULTURE]: { label: "Viticulture" },
};

export const domainSoldDataSaleMethodMap: Record<DomainSoldDataSaleMethod, MapValue> = {
  [DomainSoldDataSaleMethod.NOT_STATED]: { label: "Not stated" },
  [DomainSoldDataSaleMethod.SOLD_BY_AUCTION]: { label: "Sold by auction" },
  [DomainSoldDataSaleMethod.SOLD_BY_PRIVATE_TREATY]: { label: "Sold by private treaty" },
  [DomainSoldDataSaleMethod.SOLD_PRIOR_TO_AUCTION]: { label: "Sold prior to auction" },
  [DomainSoldDataSaleMethod.WITHDRAWN]: { label: "Withdrawn" },
};

export const familyHomeTypeMap: Record<FamilyHomeType, MapValue> = {
  [FamilyHomeType.FAMILY_HOME]: { label: "Family Home" },
  [FamilyHomeType.HOLIDAY_HOUSE]: { label: "Holiday house" },
};

export const finalOutcomeStatus: Record<FinalOutcomeStatus, MapValue> = {
  [FinalOutcomeStatus.CONDITIONAL]: { label: "Conditional" },
  [FinalOutcomeStatus.UNCONDITIONAL]: { label: "Unconditional" },
};

export const gamePlanOptionTagMap: Record<GamePlanOptionTag, MapValue> = {
  [GamePlanOptionTag.DEVELOPMENTS]: {
    label: "Developments",
    badgeProps: { colour: "grey" },
  },
  [GamePlanOptionTag.DIVERSIFICATION]: {
    label: "Diversification",
    badgeProps: { colour: "grey" },
  },
  [GamePlanOptionTag.EQUITY]: {
    label: "Equity",
    badgeProps: { colour: "grey" },
  },
  [GamePlanOptionTag.HOLDING_COSTS]: {
    label: "Holding costs",
    badgeProps: { colour: "grey" },
  },
  [GamePlanOptionTag.LVR]: {
    label: "LVR",
    badgeProps: { colour: "grey" },
  },
  [GamePlanOptionTag.PASSIVE_INCOME]: {
    label: "Passive income",
    badgeProps: { colour: "grey" },
  },
};

export const gamePlanPurchaseTypeMap: Record<GamePlanPurchaseType, MapValue> = {
  [GamePlanPurchaseType.INVESTMENT]: {
    label: "Investment",
    alternativeLabel: "Investment purchase",
  },
  [GamePlanPurchaseType.OWNER_OCCUPIER]: {
    label: "Owner-occupier",
    alternativeLabel: "Family home purchase",
  },
  [GamePlanPurchaseType.INVESTMENT_THEN_OWNER_OCCUPIER]: {
    label: "Investment, then owner-occupier",
    alternativeLabel: "Investment purchase",
  },
  [GamePlanPurchaseType.OWNER_OCCUPIER_THEN_INVESTMENT]: {
    label: "Owner-occupier, then investment",
    alternativeLabel: "Family home purchase",
  },
};

export const gamePlanStatusMap: Record<GamePlanStatus, MapValue> = {
  [GamePlanStatus.OPTIONS_IN_PROGRESS]: {
    label: "Options in progress",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [GamePlanStatus.OPTIONS_PUBLISHED]: {
    label: "Options published",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [GamePlanStatus.COMPLETE]: {
    label: "Complete",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const identityTypeMap: Record<IdentityType, MapValue> = {
  [IdentityType.BIRTH_CERTIFICATE]: { label: "Birth certificate" },
  [IdentityType.DRIVERS_LICENCE]: { label: "Drivers licence" },
  [IdentityType.MARRIAGE_CERTIFICATE]: { label: "Marriage certificate" },
  [IdentityType.PASSPORT]: { label: "Passport" },
};

export const inspectionIssueTypeMap: Record<InspectionIssueType, MapValue> = {
  [InspectionIssueType.NEW_ISSUE]: {
    label: "New",
    badgeProps: { colour: "orange" },
  },
  [InspectionIssueType.EXISTING_ISSUE]: {
    label: "Existing",
    badgeProps: { colour: "grey" },
  },
  [InspectionIssueType.NO_ISSUE]: {
    label: "No issue",
    badgeProps: { colour: "grey" },
  },
};

export const internalFeatureMap: Record<InternalFeature, MapValue> = {
  [InternalFeature.SECOND_LOUNGE]: { label: "Second lounge" },
  [InternalFeature.TV_ROOM]: { label: "TV room" },
  [InternalFeature.STUDY]: { label: "Study" },
  [InternalFeature.WALK_IN_ROBES]: { label: "Walk in robes" },
  [InternalFeature.HOME_OFFICE]: { label: "Home office" },
  [InternalFeature.PANTRY]: { label: "Pantry" },
  [InternalFeature.SEPARATE_LAUNDRY]: { label: "Separate laundry" },
};

export const jobTypeMap: Record<JobType, MapValue> = {
  [JobType.FAMILY_HOME_PURCHASE]: { label: "Family Home purchase" },
  [JobType.GAME_PLAN_ONLY]: { label: "Game plan only" },
  [JobType.INVESTMENT_PURCHASE]: { label: "Investment purchase" },
};

export const lmhWithNoneMap: Record<LMHWithNone, MapValue> = {
  [LMHWithNone.NONE]: { label: "None", badgeProps: { colour: "grey" } },
  [LMHWithNone.LOW]: { label: "Low", badgeProps: { colour: "scaleLightGreen" } },
  [LMHWithNone.MEDIUM]: { label: "Medium", badgeProps: { colour: "scaleYellow" } },
  [LMHWithNone.HIGH]: { label: "High", badgeProps: { colour: "scaleRed" } },
};

export const lmhMap: Record<LMH, MapValue> = {
  [LMH.LOW]: {
    label: "Low",
    badgeProps: { colour: "scaleLightGreen" },
  },
  [LMH.MEDIUM]: {
    label: "Medium",
    badgeProps: { colour: "scaleYellow" },
  },
  [LMH.HIGH]: {
    label: "High",
    badgeProps: { colour: "scaleRed" },
  },
};

export const loanAdjustmentTypeMap: Record<LoanAdjustmentType, MapValue> = {
  [LoanAdjustmentType.OFFSET]: { label: "Loan offset" },
  [LoanAdjustmentType.PREPAYMENT]: { label: "Loan prepayment" },
};

export const loanTypeMap: Record<LoanType, MapValue> = {
  [LoanType.FIXED]: { label: "Fixed" },
  [LoanType.VARIABLE]: { label: "Variable" },
};

export const mcServiceMap: Record<MCService, MapValue> = {
  [MCService.FAMILY_HOME]: { label: "Family Home" },
  [MCService.INVESTMENT]: { label: "Investment property" },
  [MCService.PROPERTY_MANAGEMENT]: { label: "Property management" },
  [MCService.RENOVATION]: { label: "Renovation" },
};

export const motivationMap: Record<Motivation, MapValue> = {
  [Motivation.EXTREMELY_HIGH]: { label: "Extremely high" },
  [Motivation.HIGH]: { label: "High" },
  [Motivation.MEDIUM]: { label: "Medium" },
  [Motivation.LOW]: { label: "Low" },
};

export const neighbourTypeMap: Record<NeighbourType, MapValue> = {
  [NeighbourType.ACROSS_THE_STREET]: { label: "Across the street" },
  [NeighbourType.NEXT_DOOR_LEFT]: { label: "Next door left" },
  [NeighbourType.NEXT_DOOR_RIGHT]: { label: "Next door right" },
  [NeighbourType.UPSTAIRS]: { label: "Upstairs" },
  [NeighbourType.DOWNSTAIRS]: { label: "Downstairs" },
  [NeighbourType.NEIGHBOURS_BEHIND]: { label: "Neighbours behind" },
};

export const neighbourOccupancyTypeMap: Record<NeighbourOccupancyType, MapValue> = {
  [NeighbourOccupancyType.OWNER_OCCUPIED]: { label: "Owner Occupied" },
  [NeighbourOccupancyType.LEASED]: { label: "Leased" },
  [NeighbourOccupancyType.UNKNOWN]: { label: "Unknown" },
};

export const offerStatusTypeMap: Record<OfferStatusType, MapValue> = {
  [OfferStatusType.ACCEPTED]: {
    label: "Accepted",
    badgeProps: { colour: "green" },
  },
  [OfferStatusType.PENDING]: {
    label: "Pending",
    badgeProps: { colour: "orange" },
  },
  [OfferStatusType.REJECTED]: {
    label: "Rejected",
    badgeProps: { colour: "grey" },
  },
};

export const offerTypeMap: Record<OfferType, MapValue> = {
  [OfferType.COUNTER_OFFER]: { label: "Counter offer" },
  [OfferType.OTHER_OFFER]: { label: "Other offer" },
  [OfferType.OUR_OFFER]: { label: "Our offer" },
};

export const outsideFeatureMap: Record<OutsideFeature, MapValue> = {
  [OutsideFeature.DECK]: { label: "Deck" },
  [OutsideFeature.SHED_OR_WORKSHOP]: { label: "Shed/workshop" },
  [OutsideFeature.COURTYARD]: { label: "Courtyard" },
  [OutsideFeature.POOL]: { label: "Pool" },
  [OutsideFeature.FENCED_REAR_GARDEN]: { label: "Fenced rear garden" },
  [OutsideFeature.FRONT_GARDEN]: { label: "Front garden" },
};

export const paymentMethodMap: Record<PaymentMethod, MapValue> = {
  [PaymentMethod.CREDIT_CARD]: { label: "Credit card" },
  [PaymentMethod.DIRECT_DEBIT]: { label: "Direct debit" },
  [PaymentMethod.GOOGLE_PAY]: { label: "Google Pay" },
  [PaymentMethod.APPLE_PAY]: { label: "Apple Pay" },
};

export const paymentStatusMap: Record<PaymentStatus, MapValue> = {
  [PaymentStatus.PAID]: {
    label: "Paid",
    badgeProps: { colour: "green" },
  },
  [PaymentStatus.PENDING]: {
    label: "Pending",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PaymentStatus.SCHEDULED]: {
    label: "Scheduled",
    badgeProps: { colour: "grey" },
  },
  [PaymentStatus.PROCESSING]: {
    label: "Processing",
    badgeProps: { colour: "orange" },
  },
  [PaymentStatus.CANCELLED]: {
    label: "Cancelled",
    badgeProps: { colour: "red" },
  },
  [PaymentStatus.ON_HOLD]: {
    label: "On hold",
    badgeProps: { colour: "orange" },
  },
  [PaymentStatus.REFUNDED]: {
    label: "Refunded",
    badgeProps: { colour: "orange", isSecondary: true },
  },
  [PaymentStatus.REFUND_PENDING]: {
    label: "Refund pending",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PaymentStatus.FAILED]: {
    label: "Failed",
    badgeProps: { colour: "red" },
  },
};

export const paymentTriggerMap: Record<PaymentTrigger, MapValue> = {
  [PaymentTrigger.ON_EXCHANGE]: { label: "On exchange" },
  [PaymentTrigger.ON_SETTLEMENT]: { label: "On settlement" },
};

export const postExchangeTypeMap: Record<PostExchangeType, MapValue> = {
  [PostExchangeType.LETTER]: { label: "Post exchange letter" },
};

export const prePurchaseReportTypeMap: Record<PrePurchaseReportType, MapValue> = {
  [PrePurchaseReportType.BUILDING]: { label: "Building" },
  [PrePurchaseReportType.PEST]: { label: "Pest" },
  [PrePurchaseReportType.ELECTRICAL]: { label: "Electrical" },
  [PrePurchaseReportType.PLUMBING]: { label: "Plumbing" },
  [PrePurchaseReportType.STRATA]: { label: "Strata" },
};

export const professionalServiceSourceMapAdmin: Record<ProfessionalServiceSource, MapValue> = {
  [ProfessionalServiceSource.MC]: {
    label: "Milk Chocolate referral",
    badgeProps: { colour: "green", isSecondary: false },
  },
  [ProfessionalServiceSource.CLIENT]: {
    label: "Client preferred",
    badgeProps: { colour: "green", isSecondary: false },
  },
  [ProfessionalServiceSource.NOT_REQUIRED]: {
    label: "Not required",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const professionalServiceSourceMapClient: Record<ProfessionalServiceSource, MapValue> = {
  [ProfessionalServiceSource.MC]: {
    label: "Milk Chocolate referral",
    badgeProps: { colour: "green", isSecondary: false },
  },
  [ProfessionalServiceSource.CLIENT]: { label: "Your preferred", badgeProps: { colour: "green", isSecondary: false } },
  [ProfessionalServiceSource.NOT_REQUIRED]: {
    label: "Not required",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const professionalServiceStatusMap: Record<ProfessionalServiceStatus, MapValue> = {
  [ProfessionalServiceStatus.PENDING_SELECT_PROVIDER]: { label: "Pending select provider" },
  [ProfessionalServiceStatus.PENDING_CONTACT_PROVIDER]: { label: "Pending contact provider" },
  [ProfessionalServiceStatus.DONE]: { label: "Done" },
};

export const professionalServiceTypeMap: Record<ProfessionalServiceType, MapValue> = {
  [ProfessionalServiceType.MORTGAGE]: { label: "Mortgage" },
  [ProfessionalServiceType.CONVEYANCING]: { label: "Conveyancing" },
  [ProfessionalServiceType.INSURANCE]: { label: "Insurance" },
  [ProfessionalServiceType.INSPECTION_REPORTS]: { label: "Inspection reports" },
};

export const profileStatusMap: Record<ProfileStatus, MapValue> = {
  [ProfileStatus.ACTIVE]: { label: "Active" },
  [ProfileStatus.ARCHIVED]: { label: "Archived" },
  [ProfileStatus.DRAFT]: { label: "Draft" },
};

export const propertyFeatureMap: Record<PropertyFeature, MapValue> = {
  [PropertyFeature.WOODEN_FLOORS]: { label: "Wooden floors" },
  [PropertyFeature.SOLAR]: { label: "Solar" },
  [PropertyFeature.DUCTED_AC]: { label: "Ducted AC" },
  [PropertyFeature.RAINWATER_TANKS]: { label: "Rainwater tanks" },
  [PropertyFeature.UNDERFLOOR_HEATING]: { label: "Underfloor heating" },
  [PropertyFeature.DOUBLE_GLAZING]: { label: "Double glazing" },
  [PropertyFeature.TEN_STAR_RATED_INSULATION]: { label: "10-star rated insulation" },
  [PropertyFeature.GAS]: { label: "Gas" },
  [PropertyFeature.ALARM]: { label: "Alarm" },
  [PropertyFeature.STUDIO_OR_GRANNY_FLAT]: { label: "Studio/granny flat" },
};

export const propertyShortlistReasonNoStatus: Record<PropertyShortlistReason, MapValue> = {
  [PropertyShortlistReason.BLOCK]: { label: "Block" },
  [PropertyShortlistReason.ASPECT]: { label: "Aspect" },
  [PropertyShortlistReason.LOCATION]: { label: "Location" },
  [PropertyShortlistReason.ZONING]: { label: "Zoning" },
  [PropertyShortlistReason.ASSET]: { label: "Asset" },
  [PropertyShortlistReason.INVESTMENT]: { label: "Investment" },
};

export const propertyShortlistReasonRejected: Record<PropertyShortlistReason, MapValue> = {
  [PropertyShortlistReason.BLOCK]: { label: "The land size is too small" },
  [PropertyShortlistReason.ASPECT]: { label: "Undesirable aspect" },
  [PropertyShortlistReason.LOCATION]: { label: "Undesirable location" },
  [PropertyShortlistReason.ZONING]: { label: "Zoning doesn't meet requirements" },
  [PropertyShortlistReason.ASSET]: { label: "Undesirable property condition" },
  [PropertyShortlistReason.INVESTMENT]: { label: "Undesirable architectural style" },
};

export const propertyShortlistReasonNotRejected: Record<PropertyShortlistReason, MapValue> = {
  [PropertyShortlistReason.BLOCK]: { label: "Suitable land size" },
  [PropertyShortlistReason.ASPECT]: { label: "Desirable aspect" },
  [PropertyShortlistReason.LOCATION]: { label: "Desirable location" },
  [PropertyShortlistReason.ZONING]: { label: "Zoning meets requirements" },
  [PropertyShortlistReason.ASSET]: { label: "Desirable property condition" },
  [PropertyShortlistReason.INVESTMENT]: { label: "Desirable architectural style" },
};

export const propertyStyleMap: Record<PropertyStyle, MapValue> = {
  [PropertyStyle.PERIOD]: { label: "Period style e.g. Victorian, Federation, Art-deco, Post-war" },
  [PropertyStyle.MODERN]: { label: "Modern e.g. 20th century, comtemporary" },
};

export const propertyTypeMap: Record<PropertyType, MapValue> = {
  [PropertyType.APARTMENT]: { label: "Apartment" },
  [PropertyType.COMMERCIAL]: { label: "Commercial" },
  [PropertyType.HOUSE]: { label: "House" },
  [PropertyType.LAND]: { label: "Land" },
};

export const purchaseStatusMap: Record<PurchaseStatus, MapValue> = {
  [PurchaseStatus.SEARCH_PENDING_ACTIVATION]: {
    label: "Search pending activation",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PurchaseStatus.ACTIVATE_SEARCH_IN_PROGRESS]: {
    label: "Activate search in progress",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PurchaseStatus.SEARCH_TRACKER_ACTIVE]: {
    label: "Search Tracker active",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PurchaseStatus.SEARCH_TRACKER_PAUSED]: {
    label: "Search Tracker paused",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [PurchaseStatus.SEARCH_TRACKER_COMPLETE]: {
    label: "Search Tracker complete",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const purchaseTrackerItemStatusMap: Record<PurchaseTrackerItemStatus, MapValue> = {
  [PurchaseTrackerItemStatus.ACTIVE]: { label: "Active" },
  [PurchaseTrackerItemStatus.ACTIVE_REQUIRED_ACTION]: { label: "Active, required action" },
  [PurchaseTrackerItemStatus.DONE]: { label: "Done" },
  [PurchaseTrackerItemStatus.PENDING]: { label: "Pending" },
  [PurchaseTrackerItemStatus.UPLOADED]: { label: "Uploaded" },
  [PurchaseTrackerItemStatus.NOT_STARTED]: { label: "Not started" },
  [PurchaseTrackerItemStatus.IN_PROGRESS]: { label: "In progress" },
  [PurchaseTrackerItemStatus.READY_FOR_CLIENT]: { label: "Ready for client" },
  [PurchaseTrackerItemStatus.COMPLETE]: { label: "Complete" },
};

export const purchaseTrackerStageTypeMap: Record<PurchaseTrackerStageType, MapValue> = {
  [PurchaseTrackerStageType.SEARCH]: { label: "Search" },
  [PurchaseTrackerStageType.REVIEW]: { label: "Review" },
  [PurchaseTrackerStageType.DUE_DILIGENCE]: { label: "Due diligence" },
  [PurchaseTrackerStageType.NEGOTIATIONS]: { label: "Negotiations" },
  [PurchaseTrackerStageType.EXCHANGE_AND_SETTLE]: { label: "Exchange and settle" },
};

export const recurrenceFrequencyMap: Record<RecurrenceFrequency, MapValue> = {
  [RecurrenceFrequency.DAILY]: { label: "Daily" },
  [RecurrenceFrequency.WEEKLY]: { label: "Weekly" },
  [RecurrenceFrequency.FORTNIGHTLY]: { label: "Fortnightly" },
  [RecurrenceFrequency.MONTHLY]: { label: "Monthly" },
  [RecurrenceFrequency.QUARTERLY]: { label: "Quarterly" },
  [RecurrenceFrequency.ANNUAL]: { label: "Annual" },
};

export const renovationTypeMap: Record<RenovationType, MapValue> = {
  [RenovationType.COSMETIC_RENOVATION]: { label: "Cosmetic renovation" },
  [RenovationType.GRANNY_FLAT]: { label: "Granny flat" },
  [RenovationType.MAJOR_RENOVATION]: { label: "Structural renovation" },
  [RenovationType.NEW_BUILD]: { label: "New build" },
  [RenovationType.SUBDIVISION]: { label: "Subdivision" },
  [RenovationType.SUBDIVISION_ONE_NEW]: { label: "Subdivision (one new)" },
};

export const repaymentTypeMap: Record<RepaymentType, MapValue> = {
  [RepaymentType.PRINCIPAL_AND_INTEREST]: { label: "Principal and interest" },
  [RepaymentType.INTEREST_ONLY_ONE_YEAR]: { label: "Interest only 1 year" },
  [RepaymentType.INTEREST_ONLY_TWO_YEARS]: { label: "Interest only 2 years" },
  [RepaymentType.INTEREST_ONLY_THREE_YEARS]: { label: "Interest only 3 years" },
  [RepaymentType.INTEREST_ONLY_FOUR_YEARS]: { label: "Interest only 4 years" },
  [RepaymentType.INTEREST_ONLY_FIVE_YEARS]: { label: "Interest only 5 years" },
};

export const saleMethodMap: Record<SaleMethod, MapValue> = {
  [SaleMethod.AUCTION]: { label: "Auction" },
  [SaleMethod.PRIVATE_TREATY]: { label: "Private treaty" },
};

export const saleOfferTypeMap: Record<SaleOfferType, MapValue> = {
  [SaleOfferType.UNCONDITIONAL]: { label: "Unconditional" },
  [SaleOfferType.CONDITIONAL_SHORT]: { label: "Conditional (short)" },
  [SaleOfferType.CONDITIONAL_LONG]: { label: "Conditional (long)" },
  [SaleOfferType.CONDITIONAL_QLD]: { label: "Conditional (QLD)" },
  [SaleOfferType.CONDITIONAL_SA]: { label: "Conditional (SA)" },
  [SaleOfferType.CONDITIONAL_QLD_ALT]: { label: "Conditional (QLD alt.)" },
};

export const saleReasonMap: Record<SaleReason, MapValue> = {
  [SaleReason.UNKNOWN]: { label: "Unknown" },
  [SaleReason.BUYING_ELSEWHERE]: { label: "Buying elsewhere" },
  [SaleReason.CHANGE_IN_JOB]: { label: "Change in job" },
  [SaleReason.DECEASED_ESTATE]: { label: "Deceased estate" },
  [SaleReason.CHANGE_IN_RELATIONSHIP]: { label: "Change in relationship status" },
  [SaleReason.DEFAULT_ON_MORTGAGE]: { label: "Default on the mortgage" },
  [SaleReason.INVESTOR_LIQUIDISING_FUNDS]: { label: "Investor liquidising funds" },
  [SaleReason.RETIREMENT_VILLAGE]: { label: "Retirement village" },
  [SaleReason.FLIPPED_FOR_PROFIT]: { label: "Flipped for profit" },
  [SaleReason.OTHER]: { label: "Other" },
};

export const schoolSectorMap: Record<SchoolSector, MapValue> = {
  [SchoolSector.CATHOLIC]: { label: "Catholic school" },
  [SchoolSector.GOVERNMENT]: { label: "Public school" },
  [SchoolSector.INDEPENDENT]: { label: "Private school" },
};

export const settlementStatusMap: Record<SettlementStatus, MapValue> = {
  [SettlementStatus.NOT_FINAL]: { label: "Not final" },
  [SettlementStatus.FINAL]: { label: "Final" },
};

export const shortlistProgressStatusMap: Record<ShortlistProgressStatus, MapValue> = {
  [ShortlistProgressStatus.PURCHASE_STAGE_2_REVIEW]: {
    label: "Purchase stage 2 review",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistProgressStatus.PURCHASE_STAGE_3_DUE_DILIGENCE]: {
    label: "Purchase stage 3 due diligence",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistProgressStatus.PURCHASE_STAGE_4_NEGOTIATIONS]: {
    label: "Purchase stage 4 negotiations",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistProgressStatus.PURCHASE_STAGE_5_EXCHANGE_AND_SETTLE]: {
    label: "Purchase stage 5 exchange and settle",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistProgressStatus.SETTLEMENT_COMPLETE]: {
    label: "Settlement complete",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistProgressStatus.PURCHASE_REJECTED]: {
    label: "Purchase rejected",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const shortlistStatusMap: Record<ShortlistStatus, MapValue> = {
  [ShortlistStatus.TOP_RESULT]: {
    label: "Top result",
    badgeProps: { colour: "orange" },
  },
  [ShortlistStatus.SHORT_LIST]: {
    label: "Shortlist",
    badgeProps: { colour: "orange", isSecondary: true },
  },
  [ShortlistStatus.FOR_FURTHER_CONSIDERATION]: {
    label: "For further consideration",
    badgeProps: { colour: "orange", isSecondary: true },
  },
  [ShortlistStatus.REJECTED]: {
    label: "Rejected",
    badgeProps: { colour: "grey", isSecondary: true },
  },
  [ShortlistStatus.HIDDEN]: {
    label: "Hidden",
    badgeProps: { colour: "grey", isSecondary: true },
  },
};

export const typicalPropertyArchitecturalStyleMap: Record<TypicalPropertyArchitecturalStyle, MapValue> = {
  [TypicalPropertyArchitecturalStyle.ADAPTIVE]: { label: "Adaptive" },
  [TypicalPropertyArchitecturalStyle.BRUTALIST]: { label: "Brutalist" },
  [TypicalPropertyArchitecturalStyle.CONVENTIONAL_STYLE_WEATHERBOARD]: {
    label: "Conventional lowset brick veneer (red or blonde brick)",
  },
  [TypicalPropertyArchitecturalStyle.DUTCH_COLONIAL]: { label: "Dutch Colonial" },
  [TypicalPropertyArchitecturalStyle.FEDERATION_ARTS_AND_CRAFTS]: { label: "Federation Arts and Crafts" },
  [TypicalPropertyArchitecturalStyle.FEDERATION_BUNGALOW]: { label: "Federation Bungalow" },
  [TypicalPropertyArchitecturalStyle.FEDERATION_FILIGREE]: { label: "Federation Filigree" },
  [TypicalPropertyArchitecturalStyle.FEDERATION_QUEEN_ANNE]: { label: "Federation Queen Anne" },
  [TypicalPropertyArchitecturalStyle.FEDERATION_REVIVAL]: { label: "Federation Revival" },
  [TypicalPropertyArchitecturalStyle.HIGHRISES]: { label: "Highrises" },
  [TypicalPropertyArchitecturalStyle.INTERNATIONAL_STYLE]: { label: "International Style" },
  [TypicalPropertyArchitecturalStyle.INTER_WAR_CALIFORNIAN_BUNGALOW]: { label: "Inter-War Californian Bungalow" },
  [TypicalPropertyArchitecturalStyle.INTER_WAR_GEORGIAN_REVIVAL]: { label: "Inter-War Georgian Revival" },
  [TypicalPropertyArchitecturalStyle.INTER_WAR_OLD_ENGLISH]: { label: "Inter-War Old English" },
  [TypicalPropertyArchitecturalStyle.INTER_WAR_SPANISH_MISSION]: { label: "Inter-War Spanish Mission" },
  [TypicalPropertyArchitecturalStyle.INTER_WAR_STREAMLINE]: { label: "Inter-War Streamline" },
  [TypicalPropertyArchitecturalStyle.MIGRANT_HOUSE]: { label: "Migrant House" },
  [TypicalPropertyArchitecturalStyle.MODERNE_INTER_WAR_ASHGROVIAN]: { label: "Moderne Inter-War Ashgrovian" },
  [TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GEORGIAN_STYLE]: { label: "Old Colonial Georgian Style" },
  [TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GOTHIC_PICTURESQUE]: { label: "Old Colonial Gothic Picturesque" },
  [TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GRECIAN_STYLE]: { label: "Old Colonial Grecian Style" },
  [TypicalPropertyArchitecturalStyle.OLD_COLONIAL_REGENCY_STYLE]: { label: "Old Colonial Regency Style" },
  [TypicalPropertyArchitecturalStyle.PAINTED_AND_RENDERED_TRIPLE_FRONTED_BRICK_VENEER]: {
    label: "Painted and rendered triple fronted brick veneer",
  },
  [TypicalPropertyArchitecturalStyle.PAVILION_STYLE]: { label: "Pavilion Style" },
  [TypicalPropertyArchitecturalStyle.QUEENSLANDER_STYLE]: { label: "Queenslander Style" },
  [TypicalPropertyArchitecturalStyle.RANCH_STYLE_MURCUTT_DREW_STEEL_AND_CORRUGATED_IRON_HOUSE]: {
    label: "Ranch-style Murcutt/Drew steel and corrugated iron house",
  },
  [TypicalPropertyArchitecturalStyle.REGIONAL_GABLED_COTTAGE]: { label: "Regional Gabled Cottage" },
  [TypicalPropertyArchitecturalStyle.TIMBER_AND_FIBRO_FISHERMANS_COTTAGE]: {
    label: "Timber and fibro fisherman's cottage",
  },
  [TypicalPropertyArchitecturalStyle.TRIPLE_FRONT_CREAM_BRICK]: { label: "Triple front (cream brick)" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_FILIGREE]: { label: "Victorian Filigree" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_FREE_GOTHIC]: { label: "Victorian Free Gothic" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_GEORGIAN]: { label: "Victorian Georgian" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_REGENCY]: { label: "Victorian Regency" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_RUSTIC_GOTHIC]: { label: "Victorian Rustic Gothic" },
  [TypicalPropertyArchitecturalStyle.VICTORIAN_TUDOR]: { label: "Victorian Tudor" },
  [TypicalPropertyArchitecturalStyle.WATERFALL_ART_DECO]: { label: "Waterfall (Art Deco)" },
};

export const typicalPropertyPeriodMap: Record<TypicalPropertyPeriod, MapValue> = {
  [TypicalPropertyPeriod.OLD_COLONIAL]: { label: "Old Colonial Period" },
  [TypicalPropertyPeriod.VICTORIAN]: { label: "Victorian Period" },
  [TypicalPropertyPeriod.FEDERATION]: { label: "Federation Period" },
  [TypicalPropertyPeriod.INTER_WAR]: { label: "Inter-War Period" },
  [TypicalPropertyPeriod.POST_WAR]: { label: "Post-War Period" },
  [TypicalPropertyPeriod.LATE_TWENTIETH_CENTURY]: { label: "Late Twentieth Century" },
  [TypicalPropertyPeriod.CONTEMPORARY]: { label: "Contemporary" },
};

export const workPlaceMap: Record<WorkPlace, MapValue> = {
  [WorkPlace.HOME]: { label: "At home" },
  [WorkPlace.OFFICE]: { label: "At an office" },
  [WorkPlace.RETIRED]: { label: "I'm retired" },
  [WorkPlace.VARIOUS_LOCATIONS]: { label: "Various locations" },
};

export const yourMarketComparativeReasonMap: Record<YourMarketComparativeReason, MapValue> = {
  [YourMarketComparativeReason.NOT_MC_GRADE_SUBURB]: {
    label: "Access to Milk Chocolate grade suburbs within your budget are unavailable",
  },
  [YourMarketComparativeReason.SUB_DIVISIONS_UNAVAILABLE]: {
    label:
      "No locations available within your budget that are suitable for sub-divisions to meet your game plan requirements",
  },
  [YourMarketComparativeReason.COSMETIC_RENOVATIONS_UNAVAILABLE]: {
    label:
      "No locations available within your budget that are suitable for cosmetic renovations to meet your game plan requirements",
  },
  [YourMarketComparativeReason.STRUCTURAL_RENOVATIONS_UNAVAILABLE]: {
    label:
      "No locations available within your budget that are suitable for structural renovations to meet your game plan requirements",
  },
  [YourMarketComparativeReason.GRANNY_FLAT_UNAVAILABLE]: {
    label:
      "No locations available within your budget that are suitable for granny flat builds to meet your game plan requirements",
  },
  [YourMarketComparativeReason.SHORT_TERM_LETTING_UNAVAILABLE]: {
    label: "Opportunity for locations that meet high demand for short-term letting within your budget is not available",
  },
  [YourMarketComparativeReason.EXISTING_HOME_FOR_THE_STATE]: {
    label: "You currently own assets in other states that would have otherwise met our requirements",
  },
  [YourMarketComparativeReason.UNSUITABLE_RENTAL_YIELD]: {
    label: "The rental yield required to meet your cash-flow requirements is not achievable",
  },
  [YourMarketComparativeReason.FREESTANDING_HOME_UNAVAILABLE]: {
    label: "The ability to acquire a freestanding house within your budget is not possible",
  },
};

export const yourMarketLikedReasonMap: Record<YourMarketLikedReason, MapValue> = {
  [YourMarketLikedReason.MC_GRADE_SUBURB]: {
    label: "Access to Milk Chocolate grade suburbs within your budget are available",
  },
  [YourMarketLikedReason.SUB_DIVISIONS_AVAILABLE]: {
    label:
      "Locations available within your budget that are suitable for sub-divisions to meet your game plan requirements",
  },
  [YourMarketLikedReason.COSMETIC_RENOVATIONS_AVAILABLE]: {
    label:
      "Locations available within your budget that are suitable for cosmetic renovations to meet your game plan requirements",
  },
  [YourMarketLikedReason.STRUCTURAL_RENOVATIONS_AVAILABLE]: {
    label:
      "Locations available within your budget that are suitable for structural renovations to meet your game plan requirements",
  },
  [YourMarketLikedReason.GRANNY_FLAT_AVAILABLE]: {
    label:
      "Locations available within your budget that are suitable for granny flat builds to meet your game plan requirements",
  },
  [YourMarketLikedReason.SHORT_TERM_LETTING_AVAILABLE]: {
    label: "Opportunity for locations that meet high demand for short-term letting within your budget",
  },
  [YourMarketLikedReason.FIRST_HOME_FOR_THE_STATE]: {
    label: "You currently don't own any other assets in this state",
  },
  [YourMarketLikedReason.SUITABLE_RENTAL_YIELD]: {
    label: "The rental yield required to meet your cash-flow requirements is achievable",
  },
  [YourMarketLikedReason.FREESTANDING_HOME_AVAILABLE]: {
    label: "The ability to acquire a freestanding house within your budget",
  },
};

export const additionalPurchaserRelationshipMap: Record<AdditionalPurchaserRelationship, MapValue> = {
  [AdditionalPurchaserRelationship.FAMILY_MEMBER]: {
    value: AdditionalPurchaserRelationship.FAMILY_MEMBER,
    label: "Family member",
  },
  [AdditionalPurchaserRelationship.SPOUSE]: { value: AdditionalPurchaserRelationship.SPOUSE, label: "Spouse" },
  [AdditionalPurchaserRelationship.FRIEND]: { value: AdditionalPurchaserRelationship.FRIEND, label: "Friend" },
  [AdditionalPurchaserRelationship.OTHER]: { value: AdditionalPurchaserRelationship.OTHER, label: "Other" },
};

export const tenancyMap: Record<Tenancy, MapValue> = {
  [Tenancy.SUBJECT_TO_TENANCY]: { label: "Subject to tenancy" },
  [Tenancy.VACANT_POSSESSION]: { label: "Vacant possession" },
  [Tenancy.VENDOR_LEASE_BACK]: { label: "Vendor lease back" },
};

export const leaseStatusMap: Record<PropertyLeaseStatus, MapValue> = {
  [PropertyLeaseStatus.CONTRACT]: { label: "Contract" },
  [PropertyLeaseStatus.OWNER_OCCUPIED]: { label: "Owner occupied" },
  [PropertyLeaseStatus.PERIODIC]: { label: "Periodic" },
  [PropertyLeaseStatus.VACANT]: { label: "Vacant" },
};

export const landUsePossibilityMap: Record<LandUsesPossibility, MapValue> = {
  [LandUsesPossibility.YES]: { label: "Yes" },
  [LandUsesPossibility.NO]: { label: "No" },
  [LandUsesPossibility.DEPENDS]: { label: "Depends" },
};

export const landUsePropertyTypeMap: Record<LandUsesPropertyType, MapValue> = {
  [LandUsesPropertyType.AGED_CARE]: { label: "Aged care" },
  [LandUsesPropertyType.AGRICULTURE]: { label: "Agriculture" },
  [LandUsesPropertyType.APARTMENT]: { label: "Apartment" },
  [LandUsesPropertyType.DUAL_OCCUPANCY]: { label: "Dual occupancy" },
  [LandUsesPropertyType.HOUSE]: { label: "House" },
  [LandUsesPropertyType.INDUSTRY]: { label: "Industry" },
  [LandUsesPropertyType.OFFICE]: { label: "Office" },
  [LandUsesPropertyType.RETAIL_PREMISE]: { label: "Retail premise" },
  [LandUsesPropertyType.SUBDIVISION]: { label: "Subdivision" },
  [LandUsesPropertyType.TOWNHOUSE]: { label: "Townhouse" },
  [LandUsesPropertyType.WAREHOUSE]: { label: "Warehouse" },
};
