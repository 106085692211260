import { useFormContext } from "react-hook-form";
import { SpacingValue } from "../core/spacing.styles";

import { CheckboxInput, CheckboxInputWrapper } from "./checkbox.styles";

interface eventType {
  isChecked: boolean;
  value: string;
}

type Props = {
  id: string;
  name: string;
  label: string;
  value?: string;
  isRequired?: boolean;
  defaultChecked?: boolean;
  isDisabled?: boolean;
  marginBottom?: SpacingValue;
  onChange?: (event: eventType) => void;
};

const Checkbox = ({
  id,
  name,
  label,
  value,
  isRequired = false,
  defaultChecked,
  isDisabled = false,
  marginBottom,
}: Props) => {
  const { register } = useFormContext();
  return (
    <CheckboxInputWrapper htmlFor={id} marginBottom={marginBottom}>
      <CheckboxInput
        id={id}
        {...register(name, {
          required: isRequired ? "Please choose a value." : false,
        })}
        value={value}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
      />
      <span>{label}</span>
    </CheckboxInputWrapper>
  );
};

export default Checkbox;
