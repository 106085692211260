import styled from "styled-components";
import { SpacingValue } from "../core/spacing.styles";
import { rem } from "../core/styles";

type StackProps = {
  spacing?: SpacingValue;
  direction?: "vertical" | "horizontal";
  marginBottom?: SpacingValue;
  wrap?: boolean;
};

export const Stack = styled.div<StackProps>`
  ${({ direction }) =>
    direction === "horizontal" &&
    `
      display: flex;
      align-items: center;
    `}

  > *:not(:last-child) {
    ${({ direction, spacing }) =>
      direction === "vertical"
        ? `
      margin-bottom: ${rem(spacing ?? 64)};
      `
        : `
      margin-right: ${rem(spacing ?? 64)};
      `}
  }

  ${({ wrap }) =>
    wrap &&
    `
    flex-wrap: wrap;
  `}

  margin: 0 0 ${({ marginBottom }) => (marginBottom !== undefined ? rem(marginBottom) : 0)} 0;
`;

Stack.defaultProps = {
  spacing: 64,
  direction: "vertical",
  marginBottom: 0,
};
