import { red, white } from "@mc/components/core/colours.styles";
import { rem } from "@mc/components/core/styles";
import styled from "styled-components";
import { LAYOUT_SIDE_DRAWER } from "../../../layout/layout.constants";

export const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: ${`${LAYOUT_SIDE_DRAWER}px`};
  right: 0;
  background-color: ${red};
  width: 100%;
  padding: ${rem(8)} ${rem(16)};
  color: ${white};
  z-index: 100;
`;
