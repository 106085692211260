import { Flex, Grid } from "@mc/components/grid/grid.styles";
import { Modal, useModalState } from "@mc/components/modal/modal";

import { Margin, SpaceBetween } from "@mc/components/core/spacing.styles";
import { ButtonIcon, ButtonLink, PrimaryLoadingButton } from "@mc/components/buttons";
import placeholderImage from "./placeholder.png";
// import { ButtonPrimary } from "@mc/components/buttons";
import {
  ActivePortfolioPropertyInput,
  CreateActivePortfolioPropertyMutation,
  CreateActivePortfolioPropertyMutationVariables,
  GetActiveProfileExistingPropertiesQuery,
  GetActiveProfileExistingPropertiesQueryVariables,
  GetPurchasedPropertiesQuery,
  GetPurchasedPropertiesQueryVariables,
} from "@mc/common/gql/types";

// import {
//   GetDomainPropertyByFullAddressQuery,
//   GetDomainPropertyByFullAddressQueryVariables,
// } from "@mc/common/gql/types";
import { H2 } from "@mc/components/core/typography.styles";

import { useMutation, useQuery } from "@apollo/client";
// import { useLazyQuery } from "@apollo/client";
import { CREATE_PROPERTY, GET_PURCHASED_PROPERTIES } from "./active-portfolio.gql";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { GET_ACTIVE_PROFILE_EXISTING_PROPERTIES } from "../game-plan/game-plan.gql";
import DropDownSelect from "@mc/components/dropdown/dropdown-select";
import { DateInput, InputLabel, NumberInput, TextInput } from "@mc/components/inputs/inputs.styles";
// import { GET_DOMAIN_PROPERTY_FULL_ADDRESS } from "./activate-active-portfolio-modal/activate-active-portfolio.gql";
import { Stack } from "@mc/components/stack/stack.styles";
import { PERIODS } from "../../suburb/suburb.constants";
import MonetaryInput from "@mc/components/inputs/monetary-input";
import Checkbox from "@mc/components/inputs/checkbox";
import { useNotification } from "@mc/components/notification/notification.context";
import { landUsePossibilityMap, landUsePropertyTypeMap, leaseStatusMap } from "@mc/common/utils/enum-maps";
import { ErrorMessage } from "@mc/components/inputs/errors.styles";
import { PhotoRow } from "../../suburb/suburb.styles";
import { Fragment } from "react";

interface ActivePortfolioFormData extends ActivePortfolioPropertyInput {}

const CreatePropertyModal = () => {
  const { isVisible, open, close } = useModalState();

  const [createProperty, { loading: creating }] = useMutation<
    CreateActivePortfolioPropertyMutation,
    CreateActivePortfolioPropertyMutationVariables
  >(CREATE_PROPERTY);

  const { clientId } = useParams<{ clientId: string }>();

  const { data: clientData } = useQuery<
    GetActiveProfileExistingPropertiesQuery,
    GetActiveProfileExistingPropertiesQueryVariables
  >(GET_ACTIVE_PROFILE_EXISTING_PROPERTIES, { variables: { clientId: clientId! } });

  const { data: purchasedPropertiesData } = useQuery<GetPurchasedPropertiesQuery, GetPurchasedPropertiesQueryVariables>(
    GET_PURCHASED_PROPERTIES,
    { variables: { clientId: clientId! } }
  );

  // const [searchDomainProperty] = useLazyQuery<
  //   GetDomainPropertyByFullAddressQuery,
  //   GetDomainPropertyByFullAddressQueryVariables
  // >(GET_DOMAIN_PROPERTY_FULL_ADDRESS);

  const initialLandUses: ActivePortfolioFormData["landUses"] = [
    { possibility: "", propertyType: "HOUSE" },
    { possibility: "", propertyType: "DUAL_OCCUPANCY" },
    { possibility: "", propertyType: "TOWN_HOUSE" },
    { possibility: "", propertyType: "APARTMENT" },
  ];

  const { showSuccess } = useNotification();

  const formMethods = useForm<ActivePortfolioFormData>({
    defaultValues: { imageURLs: [{ url: "" }], landUses: initialLandUses },
  });

  const { register, handleSubmit, watch, setValue, reset, control } = formMethods;

  const { errors } = formMethods.formState;

  const { fields: imageURLs, append: appendImage, remove: removeImage } = useFieldArray({ name: "imageURLs", control });

  const { fields: landUses, append: appendLandUses, remove: removeLandUses } = useFieldArray({
    name: "landUses",
    control,
  });

  const onSubmit = handleSubmit(async (formData) => {
    await createProperty({
      variables: {
        activePortfolioPropertyInput: {
          ...formData,
          client: clientId!,
        },
      },
    }).then(() => {
      showSuccess("Active portfolio activated");
      reset();
      close();
    });
  });

  const handlePropertySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;

    const existingProperty = clientData?.client?.profile?.existingProperties?.find(
      (property) => property._id === selectedId
    );

    if (existingProperty) {
      setValue(
        "fullAddress",
        existingProperty.domainPropertyDetails?.address ?? existingProperty.details?.location.singleLine ?? ""
      );
      return;
    }

    const purchasedProperty = purchasedPropertiesData?.purchasedProperties?.find(
      (property) => property.propertyId === selectedId
    );

    if (purchasedProperty) {
      setValue("fullAddress", purchasedProperty?.fullAddress ?? "");
      setValue("purchaseId", purchasedProperty.purchaseId);
      setValue("shortlistPropertyId", purchasedProperty.propertyId);
      return;
    }
  };

  // const handleSearchProperty = async (
  //   event: React.MouseEvent<HTMLButtonElement>,
  //   propertyType: "existingProperty" | "purchasedProperty"
  // ) => {
  //   event.preventDefault();
  //   await handleSearchPropertyByType(propertyType);
  // };

  // const handleSearchPropertyByType = async (propertyType: "existingProperty" | "purchasedProperty") => {
  //   const property = watch(`${propertyType}`);
  //   if (property.fullAddress) {
  //     const { data: queryData } = await searchDomainProperty({
  //       variables: { fullAddress: property?.fullAddress },
  //     });

  //     const {
  //       streetAddress: line1,
  //       suburb,
  //       state,
  //       postcode,
  //       bedrooms,
  //       bathrooms,
  //       carSpaces,
  //       areaSize: landSize,
  //       history,
  //     } = queryData?.getDomainPropertyByFullAddress ?? {};

  //     const purchaseDate = history?.lastSale?.date?.formatted ?? undefined;
  //     const purchasePrice = history?.lastSale?.price?.amount ?? undefined;

  //     reset({
  //       [`${propertyType}.line1`]: line1 ?? undefined,
  //       [`${propertyType}.suburb`]: suburb ?? undefined,
  //       [`${propertyType}.state`]: state ?? undefined,
  //       [`${propertyType}.postcode`]: postcode ?? undefined,
  //       [`${propertyType}.bedrooms`]: bedrooms ?? undefined,
  //       [`${propertyType}.bathrooms`]: bathrooms ?? undefined,
  //       [`${propertyType}.carSpaces`]: carSpaces ?? undefined,
  //       [`${propertyType}.landSize`]: landSize ?? undefined,
  //       [`${propertyType}.purchaseDate`]: purchaseDate,
  //       [`${propertyType}.purchasePrice`]: purchasePrice,
  //     });
  //   }
  // };
  return (
    <Flex justifyContent="flex-end">
      {isVisible && (
        <Modal isVisible={true} close={close} maxWidth={"1080px"}>
          <FormProvider {...formMethods}>
            <form onSubmit={onSubmit}>
              <div>
                <H2 isPrimary={true}>Purchase list</H2>
                {/* <PurchaseRadio data={purchaseProperty} register={register} /> */}

                <Stack spacing={16}>
                  <div>
                    <DropDownSelect
                      name="fullAddress"
                      onChange={handlePropertySelect}
                      options={[
                        ...(clientData?.client?.profile?.existingProperties ?? []).map((property) => ({
                          value: property._id,
                          label: property.domainPropertyDetails?.address ?? property.details?.location.singleLine ?? "",
                        })),
                        ...(purchasedPropertiesData?.purchasedProperties ?? []).map((property) => ({
                          value: property.propertyId,
                          label: property.fullAddress ?? "",
                        })),
                      ]}
                    />
                  </div>

                  {watch("fullAddress") && (
                    <>
                      <div>
                        <InputLabel>Full Address</InputLabel>
                        <TextInput {...register(`fullAddress`)} />
                      </div>

                      {/* <ButtonPrimary onClick={(e) => handleSearchProperty(e, "existingProperty")}>
                    Search Property
                  </ButtonPrimary> */}
                      <div>
                        <InputLabel>Line 1</InputLabel>
                        <TextInput {...register(`line1`)} />
                      </div>

                      <div>
                        <InputLabel>Suburb</InputLabel>
                        <TextInput {...register(`suburb`)} />
                      </div>

                      <div>
                        <InputLabel>State</InputLabel>
                        <TextInput {...register(`state`)} />
                      </div>

                      <div>
                        <InputLabel>Postcode</InputLabel>
                        <TextInput {...register(`postcode`)} />
                      </div>

                      <div>
                        <InputLabel>Bedrooms</InputLabel>
                        <NumberInput {...register(`bedrooms`, { valueAsNumber: true })} />
                      </div>

                      <div>
                        <InputLabel>Bathrooms</InputLabel>
                        <NumberInput {...register(`bathrooms`, { valueAsNumber: true })} />
                      </div>

                      <div>
                        <InputLabel>Carspaces</InputLabel>
                        <NumberInput {...register(`carSpaces`, { valueAsNumber: true })} />
                      </div>

                      <div>
                        <InputLabel>Land size</InputLabel>
                        <NumberInput {...register(`landSize`, { valueAsNumber: true })} />
                      </div>

                      <div>
                        <InputLabel>Period</InputLabel>
                        <DropDownSelect name={`period`} options={PERIODS} registerOptions={{ required: true }} />
                        {errors?.period?.type === "required" && <ErrorMessage error={`The field is required.`} />}
                      </div>

                      <div>
                        <InputLabel>Purchase date</InputLabel>
                        <DateInput
                          id={`purchaseDate`}
                          {...register(`purchaseDate`, {
                            valueAsDate: true,
                          })}
                        />
                      </div>

                      <div>
                        <InputLabel>Purchase Price</InputLabel>
                        <MonetaryInput {...register(`purchasePrice`)} />
                      </div>

                      <div>
                        <InputLabel> Property value (in January 2025)</InputLabel>
                        <MonetaryInput {...register(`propertyValueInJan2025`)} />
                      </div>

                      <div>
                        <Checkbox id={`hasLoan`} name={`hasLoan`} label="Has loan" />
                      </div>

                      {watch("hasLoan") && (
                        <>
                          <div>
                            <InputLabel> Original loan amount</InputLabel>
                            <MonetaryInput {...register(`originalLoanAmount`, { valueAsNumber: true })} />
                          </div>

                          <div>
                            <InputLabel>Loan interest rate</InputLabel>
                            <NumberInput {...register(`loanInterestRate`, { valueAsNumber: true })} />
                          </div>

                          <div>
                            <InputLabel>Initial offset amount</InputLabel>
                            <NumberInput {...register(`initalOffestAmount`, { valueAsNumber: true })} />
                          </div>
                        </>
                      )}

                      <div>
                        <InputLabel>Lease status</InputLabel>
                        <DropDownSelect
                          name={`leaseStatus`}
                          options={Object.entries(leaseStatusMap).map(([key, leaseStatus]) => ({
                            label: leaseStatus.label,
                            value: key,
                          }))}
                          registerOptions={{ required: true }}
                        />
                        {errors?.leaseStatus?.type === "required" && <ErrorMessage error={`The field is required.`} />}
                      </div>

                      {["CONTRACT", "PERIODIC"].includes(watch(`leaseStatus`) ?? "") && (
                        <>
                          <div>
                            <InputLabel>Currently weekly rent</InputLabel>
                            <MonetaryInput {...register(`currentWeeklyRent`)} />
                          </div>
                          <div>
                            <InputLabel>Lease commence date</InputLabel>
                            <DateInput
                              id={`leaseCommenceDate`}
                              {...register(`leaseCommenceDate`, {
                                valueAsDate: true,
                              })}
                            />
                          </div>

                          <div>
                            <InputLabel> Lease term (months)</InputLabel>
                            <NumberInput {...register(`leaseTermsMonths`, { valueAsNumber: true })} />
                          </div>

                          <div>
                            <InputLabel> Lease end date</InputLabel>
                            <DateInput
                              id={`leaseEndDate`}
                              {...register(`leaseEndDate`, {
                                valueAsDate: true,
                              })}
                            />
                          </div>

                          <div>
                            <InputLabel>Bond paid</InputLabel>
                            <MonetaryInput {...register(`bondPaid`)} />
                          </div>
                        </>
                      )}

                      <div>
                        <InputLabel>Year built</InputLabel>
                        <NumberInput {...register(`yearBuilt`, { valueAsNumber: true })} />
                      </div>

                      <div>
                        <InputLabel>Zoning</InputLabel>
                        <TextInput {...register(`zoning`)} />
                      </div>

                      <Grid lgCols="1fr 1fr">
                        {landUses.map((field, index) => (
                          <Fragment key={field.id}>
                            <Flex>
                              <DropDownSelect
                                name={`landUses.${index}.propertyType`}
                                options={Object.entries(landUsePropertyTypeMap).map(([value, { label }]) => ({
                                  value,
                                  label,
                                }))}
                              />
                            </Flex>

                            <Flex gap={8} alignItems="center">
                              <DropDownSelect
                                name={`landUses.${index}.possibility`}
                                options={Object.entries(landUsePossibilityMap).map(([value, { label }]) => ({
                                  value,
                                  label,
                                }))}
                              />
                              {index >= initialLandUses.length && (
                                <ButtonIcon iconId="delete" noBorder onClick={() => removeLandUses(index)} />
                              )}
                            </Flex>
                          </Fragment>
                        ))}
                      </Grid>

                      <ButtonLink type="button" onClick={() => appendLandUses({ propertyType: "", possibility: "" })}>
                        Add land uses
                      </ButtonLink>

                      <Stack spacing={16}>
                        {imageURLs.map((image, i) => (
                          <PhotoRow key={image.id}>
                            <img src={watch(`imageURLs.${i}.url`) || placeholderImage} alt={`Preview ${i + 1}`} />

                            <div>
                              <TextInput
                                {...register(`imageURLs.${i}.url`, { required: true })}
                                placeholder="Add a URL"
                              />
                              <ButtonIcon iconId="delete" onClick={() => removeImage(i)} noBorder />
                            </div>
                          </PhotoRow>
                        ))}

                        <Margin top={24}>
                          <ButtonLink onClick={() => appendImage({ url: "" })}>+ add another image</ButtonLink>
                        </Margin>
                      </Stack>
                    </>
                  )}
                </Stack>

                <SpaceBetween $marginTop={40}>
                  <ButtonLink onClick={() => close()}>Cancel</ButtonLink>
                  <PrimaryLoadingButton isLoading={creating}>Save</PrimaryLoadingButton>
                </SpaceBetween>
              </div>
            </form>
          </FormProvider>
        </Modal>
      )}
      <ButtonLink onClick={open}>Add</ButtonLink>
    </Flex>
  );
};

export default CreatePropertyModal;
