import { rem } from "@mc/components/core/styles";
import styled from "styled-components";

export const PhotoRow = styled.div`
  display: flex;
  gap: ${rem(24)};

  & > img {
    width: 140px;
    height: 275;
    flex-grow: 1;
    object-fit: cover;
  }

  & > div {
    display: flex;
    flex-grow: 1;
  }
`;
