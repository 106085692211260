import { Link } from "react-router-dom";
import styled from "styled-components";

import { rem } from "../core/styles";
import { anchorLinkBase, fontWeightMedium, fontWeightNormal, weights } from "../core/typography.styles";
import { darkGrey, red } from "../core/colours.styles";

type ButtonLinkProps = {
  $hideUnderline?: boolean;
  hasLeftIcon?: boolean;
  $isNegative?: boolean;
  $fontWeight?: Omit<keyof typeof weights, "semibold">;
};
export const ButtonLink = styled.button.attrs({ type: "button" })<ButtonLinkProps>`
  ${anchorLinkBase};
  font-weight: ${({ $fontWeight }) => ($fontWeight === "normal" ? fontWeightNormal : fontWeightMedium)};
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  text-align: left;

  &:disabled,
  &:disabled:hover {
    color: ${darkGrey};
    svg {
      fill: ${darkGrey};
    }
    &:hover {
      cursor: not-allowed;
    }
  }

  ${({ $hideUnderline }) =>
    $hideUnderline &&
    `
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  `}

  ${({ hasLeftIcon }) =>
    hasLeftIcon &&
    `
    svg {
      margin-right: ${rem(8)};
    }
  `}

  ${({ $isNegative }) =>
    $isNegative &&
    `
    color: ${red};

    &:hover {
      color: ${red};
    }
  `}
`;

export const ButtonLinkRoute = styled(Link)<ButtonLinkProps>`
  ${anchorLinkBase};
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  padding: 0;

  ${({ $hideUnderline }) =>
    $hideUnderline &&
    `
    text-decoration: none;
    
    &:hover {
      text-decoration: none;
    }
  `}
`;
