import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { baseBoxShadowFocus, marginCss, MarginProps, rem, standardFocusState } from "./styles";
import { black, lightGrey, darkGrey } from "./colours.styles";
import { SpacingValue } from "./spacing.styles";

export const fontFamilyBase = `'Graphik Web', sans-serif`;
export const fontFamilyAlt = `'Clarendon LT W03 Roman', 'serif'`;

export const fontWeightNormal = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;

type Alignments = "left" | "center" | "right";

export const weights = {
  normal: fontWeightNormal,
  medium: fontWeightMedium,
  semiBold: fontWeightSemiBold,
};

export const fontSize12 = rem(12);
export const fontSize13 = rem(13);
export const fontSize14 = rem(14);
export const fontSize16 = rem(16);
export const fontSize18 = rem(18);
export const fontSize20 = rem(20);
export const fontSize24 = rem(24);
export const fontSize28 = rem(28);

export const fontSize13LineHeight = rem(16);
export const fontSize14LineHeight = rem(20);
export const fontSize16LineHeight = rem(24);
export const fontSize18LineHeight = rem(24);
export const fontSize24LineHeight = rem(32);
export const fontSize28LineHeight = rem(32);

type HeadingProps = {
  isPrimary?: boolean;
  marginBottom?: SpacingValue;
  textAlign?: Alignments;
};

const baseHeading = css<HeadingProps>`
  font-weight: ${fontWeightMedium};
  margin: 0 0 ${({ marginBottom }) => (marginBottom !== undefined ? rem(marginBottom) : rem(24))} 0;
  ${({ textAlign }) =>
    textAlign &&
    `
    display: block;
    text-align: ${textAlign};
    width: 100%;
  `}
`;

const baseHeadingAlt = css<HeadingProps>`
  font-family: ${fontFamilyAlt};
  font-weight: ${fontWeightNormal};
  margin: 0 0 ${({ marginBottom }) => (marginBottom !== undefined ? rem(marginBottom) : rem(20))} 0;
  ${({ textAlign }) =>
    textAlign &&
    `
    display: block;
    text-align: ${textAlign};
    width: 100%;
  `}
`;

export const H1 = styled.h1<HeadingProps>`
  ${({ isPrimary = true }) => (isPrimary ? baseHeadingAlt : baseHeading)}
  font-size: ${fontSize28};
  line-height: ${fontSize28LineHeight};
`;

export const H2 = styled.h2<HeadingProps>`
  ${({ isPrimary = false }) => (isPrimary ? baseHeadingAlt : baseHeading)}
  font-size: ${fontSize24};
  line-height: ${fontSize24LineHeight};
`;

export const H3 = styled.h3<HeadingProps>`
  ${({ isPrimary = false }) => (isPrimary ? baseHeadingAlt : baseHeading)}
  font-size: ${fontSize18};
  line-height: ${fontSize18LineHeight};
`;

export const H4 = styled.h4<HeadingProps>`
  ${({ isPrimary = false }) => (isPrimary ? baseHeadingAlt : baseHeading)}
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
`;

type PStyledProps = {
  isItalic?: boolean;
  marginBottom?: SpacingValue;
  textAlign?: Alignments;
  fontWeight?: keyof typeof weights;
  fontSize?: string;
  colour?: string;
  textDecoration?: string;
};
export const PStyled = styled.p<PStyledProps>`
  display: block;
  line-height: ${fontSize16LineHeight};
  margin: 0 0 ${({ marginBottom = 24 }) => rem(marginBottom)} 0;

  ${({ isItalic }) => isItalic && `font-style: italic;`}

  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${({ fontWeight }) => fontWeight && `font-weight: ${weights[fontWeight]};`}

  ${({ colour }) => colour && `color: ${colour};`}

  ${({ fontSize = fontSize16 }) => `font-size: ${fontSize};`}

  ${({ textDecoration = "none" }) => textDecoration && `text-decoration: ${textDecoration};`}
`;

export const PStyledSecondary = styled.p<PStyledProps>`
  display: block;
  line-height: ${fontSize14LineHeight};
  margin: 0 0 ${({ marginBottom = 20 }) => rem(marginBottom)} 0;

  ${({ fontSize = fontSize14 }) => `font-size: ${fontSize};`}

  ${({ isItalic }) => isItalic && `font-style: italic;`}

  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${({ colour }) => colour && `color: ${colour};`}
`;

export const ListStyled = styled.ol`
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
  margin: 0 0 ${rem(16)} 0;
  padding: 0 0 0 ${rem(16)};

  li {
    line-height: ${fontSize16LineHeight};
    margin: 0 0 ${rem(20)} 0;
  }
`;

type AnchorProps = {
  $fontWeight?: Omit<keyof typeof weights, "semibold">;
} & MarginProps;

export const anchorLinkBase = css<AnchorProps>`
  border-radius: ${rem(4)};
  color: ${black};
  font-weight: ${({ $fontWeight }) => ($fontWeight === "normal" ? fontWeightNormal : fontWeightMedium)};
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
  text-decoration: underline;

  &:hover {
    color: ${black};
    cursor: pointer;
    text-decoration: underline;
  }

  ${standardFocusState};

  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    color: ${black};
  }

  &.disabled,
  &.disabled:hover {
    color: ${lightGrey};
    text-decoration: none;
  }

  ${() => marginCss()}
`;

export const AnchorStyled = styled(Link)`
  ${anchorLinkBase};
`;

export const AnchorOriginalStyled = styled.a`
  ${anchorLinkBase};
`;

export const MediumStyled = styled.strong`
  font-weight: ${fontWeightMedium};
`;

export const BoldStyled = styled.strong`
  font-weight: ${fontWeightSemiBold};
`;

export const SubheadLabel = styled.h2<HeadingProps>`
  ${baseHeading}
  font-size: ${fontSize13};
  line-height: ${fontSize13LineHeight};
`;

export const PlaceholderStyled = styled(PStyled)`
  color: ${darkGrey};
`;
