import styled from "styled-components";
import { Link } from "react-router-dom";
import { rem, standardFocusState } from "@mc/components/core/styles";
import { black, lightGrey } from "@mc/components/core/colours.styles";
import {
  fontSize16,
  fontSize16LineHeight,
  fontSize18,
  fontSize18LineHeight,
  fontWeightMedium,
} from "@mc/components/core/typography.styles";
import { Badge } from "@mc/components/badge/badge.styles";
import { SpacingValue } from "@mc/components/core/spacing.styles";

type PropertyCardProps = {
  marginBottom?: SpacingValue;
  hasBorder?: boolean;
};
export const PropertyCard = styled.div<PropertyCardProps>`
  display: block;
  margin-bottom: ${({ marginBottom = 40 }) => rem(marginBottom)};
  ${({ hasBorder }) =>
    hasBorder &&
    `
    border-radius: ${rem(4)};
    border: 1px solid ${lightGrey};
  `}
`;

export const PropertyCardImageDetailTop = styled.div`
  position: absolute;
  top: ${rem(10)};
  left: ${rem(10)};
  z-index: 1;
`;
export const PropertyCardImageContainer = styled.div`
  background-color: ${lightGrey};
  flex: 1;
  border-radius: ${rem(4)};
  padding-top: 56%;
  position: relative;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

type PropertyCardImageProps = {
  imageHasLoaded?: boolean;
};
export const PropertyCardImage = styled.div<PropertyCardImageProps>`
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity ease 1s;
  opacity: ${({ imageHasLoaded }) => (imageHasLoaded ? "1" : "0")};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

type PropertyCardContentProps = {
  contentPadding?: number;
};
export const PropertyCardContent = styled.div<PropertyCardContentProps>`
  padding-top: ${rem(16)};
  flex: 1;

  ${({ contentPadding }) =>
    contentPadding &&
    `
    padding-left: ${rem(contentPadding)};
    padding-right: ${rem(contentPadding)};
  `}
`;

export const PropertyCardTitle = styled.div`
  color: ${black};
  font-size: ${fontSize18};
  font-weight: ${fontWeightMedium};
  line-height: ${fontSize18LineHeight};
  margin-bottom: ${rem(16)};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export const PropertyCardLink = styled(Link)`
  color: ${black};
  display: block;
  font-size: ${fontSize18};
  font-weight: ${fontWeightMedium};
  line-height: ${fontSize18LineHeight};
  margin-bottom: ${rem(8)};
  text-decoration: none;

  ${standardFocusState}

  &:hover {
    text-decoration: underline;
  }
`;

export const PropertyCardProps = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rem(8)};
`;

export const PropertyCardDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rem(16)};

  ${Badge} {
    margin-right: ${rem(4)};
    text-decoration: none;
  }
`;

export const PropertyCardProp = styled.span`
  display: flex;
  align-items: center;
  font-size: ${fontSize16};
  font-weight: ${fontWeightMedium};
  line-height: ${fontSize16LineHeight};
  margin-right: ${rem(20)};

  svg {
    margin-right: ${rem(4)};
  }
`;
