import styled from "styled-components";
import { rem, baseBoxShadowFocus } from "../core/styles";
import { fontSize14, fontWeightMedium } from "../core/typography.styles";
import { black, highlight, lightGrey, softGrey } from "../core/colours.styles";
import { SpacingValue } from "../core/spacing.styles";

type TabsStyledProps = {
  $marginBottom?: SpacingValue;
};

export const TabsStyled = styled.div<TabsStyledProps>`
  display: flex;
  margin-bottom: ${({ $marginBottom = 48 }) => rem($marginBottom)};
  outline: 0;
  overflow-x: auto;
  white-space: nowrap;
`;

type TabsTabProps = {
  isActive?: boolean;
  isFocused: boolean;
  hasNotification?: boolean;
};
export const TabsTabStyled = styled.button<TabsTabProps>`
  background-color: transparent;
  border: none;
  border-radius: ${rem(40)};
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  padding: ${rem(12)} ${rem(16)};
  position: relative;
  text-align: left;

  /* handling ReactNode children within button */
  /* - ReactNode elements will not be clickable otherwise - */
  /* when option.label is ReactNode instead of string, this attr allows clickable event */
  > * {
    pointer-events: none;
  }

  &:hover {
    background-color: ${softGrey};
    cursor: pointer;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    opacity: 0.4;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: ${lightGrey};
      color: ${black};
      font-weight: ${fontWeightMedium};

      &:hover {
        background-color: ${lightGrey};
      }
  `}

  ${({ isFocused }) =>
    isFocused &&
    `
    box-shadow: ${baseBoxShadowFocus};
    z-index: 1;
  `}

  ${({ hasNotification }) =>
    hasNotification &&
    `
    &:after {
      content: '';
      background-color: ${highlight};
      border-radius: 50%;
      height: ${rem(6)};
      position: absolute;
      right: ${rem(8)};
      top: ${rem(8)};
      width: ${rem(6)};
    }
  `}
`;

type TabsSecondaryStyledProps = { marginBottom?: SpacingValue };

export const TabsSecondaryStyled = styled.div<TabsSecondaryStyledProps>`
  border-bottom: 1px solid ${lightGrey};
  display: flex;
  margin-bottom: ${({ marginBottom = 48 }) => rem(marginBottom)};
  outline: 0;
`;

type TabsTabSecondaryProps = {
  isActive?: boolean;
  isFocused: boolean;
  hasNotification?: boolean;
};
export const TabsTabSecondaryStyled = styled.button<TabsTabSecondaryProps>`
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: ${black};
  font-size: ${fontSize14};
  font-weight: ${fontWeightMedium};
  padding: ${rem(12)} ${rem(16)};
  position: relative;
  text-align: left;

  &:hover {
    background-color: ${lightGrey};
    cursor: pointer;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: transparent;

      &:before {
        content: '';
        background-color ${black};
        bottom: 0;
        height: ${rem(4)};
        left: 0;
        right: 0;
        position: absolute;
      }
  `}

  ${({ isFocused }) =>
    isFocused &&
    `
    box-shadow: ${baseBoxShadowFocus};
    z-index: 1;
  `}

${({ hasNotification }) =>
    hasNotification &&
    `
    &:after {
      content: '';
      background-color: ${highlight};
      border-radius: 50%;
      height: ${rem(6)};
      position: absolute;
      right: ${rem(8)};
      top: ${rem(8)};
      width: ${rem(6)};
    }
  `}
`;
