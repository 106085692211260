import { useFormContext, useController, UseControllerProps, RegisterOptions, FieldValues } from "react-hook-form";
import { MonetaryInputStyled, MontaryInputWrapperStyled } from "./monetary-input.styles";
import { useState, useEffect } from "react";
type Props<FormData extends FieldValues> = {
  name: UseControllerProps<FormData>["name"];
  registerOptions?: RegisterOptions<FormData>;
  isDisabled?: boolean;
  decimalScale?: number;
  allowDecimals?: boolean;
};

const MonetaryInput = <FormData extends FieldValues>({
  name,
  registerOptions,
  isDisabled,
  decimalScale,
  allowDecimals = false,
}: Props<FormData>) => {
  const { control } = useFormContext<FormData>();

  const { field } = useController({ control, name, rules: registerOptions ?? {} });

  function parseNumber(value: string) {
    setRawValue(value);

    if (!allowDecimals && value.includes(".")) {
      return;
    }

    if (!value.trim()) {
      field.onChange(null);
    } else if (allowDecimals || !value.includes(".")) {
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
        field.onChange(parsedValue);
      }
    }
  }

  const [rawValue, setRawValue] = useState<string>(() =>
    field.value !== undefined && field.value !== null ? String(field.value) : ""
  );

  useEffect(() => {
    if (field.value !== undefined && field.value !== null) {
      setRawValue(String(field.value));
    } else {
      setRawValue("");
    }
  }, [field.value]);

  return (
    <MontaryInputWrapperStyled prefix={"$"}>
      <MonetaryInputStyled
        id={name}
        name={name}
        value={rawValue}
        onValueChange={(value) => parseNumber(value as string)}
        disabled={isDisabled}
        aria-label={name}
        decimalScale={decimalScale}
        allowDecimals={allowDecimals}
      />
    </MontaryInputWrapperStyled>
  );
};

export default MonetaryInput;
