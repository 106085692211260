import { gql } from "@apollo/client";

export const GET_DOMAIN_PROPERTY_FULL_ADDRESS = gql`
  query GetDomainPropertyByFullAddress($fullAddress: String!) {
    getDomainPropertyByFullAddress(fullAddress: $fullAddress) {
      id
      streetAddress
      suburb
      state
      postcode
      bedrooms
      bathrooms
      carSpaces
      areaSize
      history {
        lastSale {
          date {
            formatted(format: "YYYY-MM-DD")
          }
          price {
            amount
            formatted
          }
        }
      }
    }
  }
`;

export const GET_PURCHASED_PROPERTIES = gql`
  query GetPurchasedProperties($clientId: String!) {
    purchasedProperties(clientId: $clientId) {
      propertyId
      purchaseId
      fullAddress
      settlementDate
    }
  }
`;

export const CLIENT_ACTIVE_PROPERTY = gql`
  query ActivePortfolio($clientId: String!) {
    activePortfolio(clientId: $clientId) {
      _id
      properties {
        _id
        details {
          id
          coreAttributes {
            id
            beds
            baths
            carSpaces
            landArea
          }
          location {
            id
            singleLine
          }
          images {
            id
            defaultImage {
              basePhotoUrl
            }
          }
        }
        purchaseId
      }
    }
  }
`;

export const GET_PURCHASES = gql`
  query GetPurchases($clientId: String!) {
    client(id: $clientId) {
      _id
      purchases {
        _id
        shortlistProperties(status: TOP_RESULT) {
          _id
          details {
            id
            location {
              id
              singleLine
            }
            images {
              id
              defaultImage {
                basePhotoUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PROPERTY = gql`
  mutation CreateActivePortfolioProperty($activePortfolioPropertyInput: ActivePortfolioPropertyInput!) {
    createActivePortfolioProperty(activePortfolioPropertyInput: $activePortfolioPropertyInput) {
      _id
    }
  }
`;
