import styled from "styled-components";
import { rem } from "@mc/components/core/styles";
import { fontSize16 } from "@mc/components/core/typography.styles";

export const LoginLogo = styled.div`
  img {
    width: ${rem(50)};
  }

  span {
    font-size: ${fontSize16};
    margin-left: ${rem(8)};
  }
`;
