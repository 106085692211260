import styled from "styled-components";
import { rem } from "../core/styles";
import { black, darkGrey, lightGrey } from "../core/colours.styles";
import { fontSize16, fontSize16LineHeight, weights } from "../core/typography.styles";

export const DataList = styled.ul.attrs({
  role: "table",
})`
  font-size: ${fontSize16};
  line-height: ${fontSize16LineHeight};
  list-style: none;
  margin: 0;
  padding: 0;
`;

type DataListGroupProps = {
  blackBorders?: boolean;
};
export const DataListGroup = styled.li.attrs({
  role: "rowgroup",
})<DataListGroupProps>`
  list-style: none;

  border-top: 1px solid ${({ blackBorders }) => (blackBorders ? black : lightGrey)};

  :last-child {
    border-bottom: 1px solid ${({ blackBorders }) => (blackBorders ? black : lightGrey)};
  }
`;

export const DataListRow = styled.div.attrs({
  role: "row",
})`
  display: flex;
  & > div {
    flex: 1;
  }
`;

export type DataListCellProps = {
  fontWeight?: keyof typeof weights;
  isUnderline?: boolean;
  isDarkGrey?: boolean;
  fontSize?: string;
  isLargeCell?: boolean;
  isIndented?: boolean;
  isClickable?: boolean;
};

export const DataListCell = styled.div.attrs<DataListCellProps>({
  role: "cell",
})<DataListCellProps>`
  padding: ${rem(10)} ${rem(8)};
  font-size: ${({ fontSize = fontSize16 }) => fontSize};
  font-weight: ${({ fontWeight = "normal" }) => weights[fontWeight]};
  text-decoration: ${({ isUnderline }) => (isUnderline ? "underline" : "none")};
  color: ${({ isDarkGrey }) => (isDarkGrey ? darkGrey : black)};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};

  ${({ isIndented }) =>
    isIndented &&
    `
      padding-left: ${rem(16)};
      // negative margin for adjusting the next cell
      margin-right: -${rem(8)};
    `}

  ${({ isLargeCell }) =>
    isLargeCell &&
    `
      padding-top: ${rem(14)};
      padding-bottom: ${rem(14)};
    `};
`;
