import { gql } from "@apollo/client";

export const GET_CLIENT_NAME = gql`
  query GetClientName($id: String!) {
    client(id: $id) {
      _id
      fullName
      status
      jobs {
        _id
        failedPaymentAttempts
      }
    }
  }
`;
