import { TypicalPropertyArchitecturalStyle, TypicalPropertyPeriod, UpdateSuburbInput } from "@mc/common/gql/types";
import { typicalPropertyPeriodMap } from "@mc/common/utils/enum-maps";
import { Option } from "@mc/components/dropdown/dropdown.constants";

export const MARKET_VALUE_RENOVATED = [
  {
    label: "Renovated",
    field: "renovated" as const,
  },
  {
    label: "Unrenovated",
    field: "unrenovated" as const,
  },
];

export const MARKET_VALUE_PROPERTY_TYPES = [
  {
    label: "Houses",
    field: "houses" as const,
    showInputLabels: true,
  },
  {
    label: "Apartments",
    field: "apartments" as const,
    showInputLabels: false,
  },
];

export const MARKET_VALUE_SIZES = [
  {
    label: "2 bed",
    field: "bed2" as const,
  },
  {
    label: "3 bed",
    field: "bed3" as const,
  },
  {
    label: "4 bed",
    field: "bed4" as const,
    hiddenForApartments: true,
  },
  {
    label: "5 bed",
    field: "bed5" as const,

    hiddenForApartments: true,
  },
];

export const MARKET_VALUE_RANGES = [
  { field: "lower" as const },
  { field: "median" as const },
  { field: "upper" as const },
];

export type TypicalPropertiesInputsType = {
  label: string;
  accessor: keyof UpdateSuburbInput;
  isSelectInput?: boolean;
  options?: Option[];
}[];

export const PERIODS = [
  TypicalPropertyPeriod.OLD_COLONIAL,
  TypicalPropertyPeriod.VICTORIAN,
  TypicalPropertyPeriod.FEDERATION,
  TypicalPropertyPeriod.INTER_WAR,
  TypicalPropertyPeriod.POST_WAR,
  TypicalPropertyPeriod.LATE_TWENTIETH_CENTURY,
  TypicalPropertyPeriod.CONTEMPORARY,
].map((period) => ({
  value: period,
  label: typicalPropertyPeriodMap[period].label,
}));

export const periodStyle = {
  CONTEMPORARY: [TypicalPropertyArchitecturalStyle.ADAPTIVE],
  FEDERATION: [
    TypicalPropertyArchitecturalStyle.FEDERATION_QUEEN_ANNE,
    TypicalPropertyArchitecturalStyle.FEDERATION_ARTS_AND_CRAFTS,
    TypicalPropertyArchitecturalStyle.FEDERATION_BUNGALOW,
    TypicalPropertyArchitecturalStyle.FEDERATION_FILIGREE,
  ],
  INTER_WAR: [
    TypicalPropertyArchitecturalStyle.INTER_WAR_CALIFORNIAN_BUNGALOW,
    TypicalPropertyArchitecturalStyle.INTER_WAR_OLD_ENGLISH,
    TypicalPropertyArchitecturalStyle.INTER_WAR_SPANISH_MISSION,
    TypicalPropertyArchitecturalStyle.INTER_WAR_GEORGIAN_REVIVAL,
    TypicalPropertyArchitecturalStyle.INTER_WAR_STREAMLINE,
    TypicalPropertyArchitecturalStyle.MODERNE_INTER_WAR_ASHGROVIAN,
  ],
  LATE_TWENTIETH_CENTURY: [
    // TypicalPropertyArchitecturalStyle.conventionallowestbrickveneer,
    TypicalPropertyArchitecturalStyle.PAINTED_AND_RENDERED_TRIPLE_FRONTED_BRICK_VENEER,
    TypicalPropertyArchitecturalStyle.MIGRANT_HOUSE,
    TypicalPropertyArchitecturalStyle.REGIONAL_GABLED_COTTAGE,
    TypicalPropertyArchitecturalStyle.RANCH_STYLE_MURCUTT_DREW_STEEL_AND_CORRUGATED_IRON_HOUSE,
    TypicalPropertyArchitecturalStyle.PAVILION_STYLE,
    TypicalPropertyArchitecturalStyle.FEDERATION_REVIVAL,
    TypicalPropertyArchitecturalStyle.BRUTALIST,
    TypicalPropertyArchitecturalStyle.HIGHRISES,
  ],
  OLD_COLONIAL: [
    TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GEORGIAN_STYLE,
    TypicalPropertyArchitecturalStyle.OLD_COLONIAL_REGENCY_STYLE,
    TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GRECIAN_STYLE,
    TypicalPropertyArchitecturalStyle.OLD_COLONIAL_GOTHIC_PICTURESQUE,
  ],
  POST_WAR: [
    TypicalPropertyArchitecturalStyle.WATERFALL_ART_DECO,
    // TypicalPropertyArchitecturalStyle.brickartdeco,
    TypicalPropertyArchitecturalStyle.INTERNATIONAL_STYLE,
    TypicalPropertyArchitecturalStyle.DUTCH_COLONIAL,
    TypicalPropertyArchitecturalStyle.TIMBER_AND_FIBRO_FISHERMANS_COTTAGE,
    TypicalPropertyArchitecturalStyle.CONVENTIONAL_STYLE_WEATHERBOARD,
    TypicalPropertyArchitecturalStyle.TRIPLE_FRONT_CREAM_BRICK,
  ],
  VICTORIAN: [
    TypicalPropertyArchitecturalStyle.VICTORIAN_GEORGIAN,
    TypicalPropertyArchitecturalStyle.VICTORIAN_REGENCY,
    TypicalPropertyArchitecturalStyle.VICTORIAN_FILIGREE,
    TypicalPropertyArchitecturalStyle.QUEENSLANDER_STYLE,
    TypicalPropertyArchitecturalStyle.VICTORIAN_FREE_GOTHIC,
    TypicalPropertyArchitecturalStyle.VICTORIAN_RUSTIC_GOTHIC,
    TypicalPropertyArchitecturalStyle.VICTORIAN_TUDOR,
  ],
};
