import CurrencyInput from "react-currency-input-field";
import styled, { css } from "styled-components/";
import { ButtonContainerStyled } from "../buttons/buttons.styles";
import { black, darkGrey, lightGrey, red, softGrey, white } from "../core/colours.styles";
import {
  fontSize12,
  fontSize14,
  fontSize14LineHeight,
  fontSize16,
  fontWeightMedium,
  fontWeightNormal,
} from "../core/typography.styles";
import arrowDown from "../icons/icon_chevron_down.svg";
import { CheckboxInput, CheckboxInputWrapper } from "./checkbox.styles";
import { RadioInput, RadioInputWrapper } from "./radio.styles";

export { CheckboxInput, CheckboxInputWrapper, RadioInput, RadioInputWrapper };

export const rem = (val: number): string => `${parseFloat(val.toString()) / 16}rem`;

export const formControlStyles = css<InputProps>`
  background-color: ${softGrey};
  border-radius: 2px;
  border: 1px solid ${lightGrey};
  box-sizing: border-box;
  color: ${black};
  display: block;
  height: ${({ isSmall }) => (isSmall ? rem(20) : rem(40))};
  font-size: ${({ isSmall }) => (isSmall ? fontSize12 : fontSize16)};
  font-weight: ${fontWeightMedium};
  line-height: ${({ isSmall }) => (isSmall ? 1 : 1.25)};
  padding: 0 ${({ isSmall }) => (isSmall ? rem(8) : rem(12))};
  width: 100%;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    outline: 0;
    border: 1px solid ${darkGrey};
    background-color: ${white};
  }

  &::placeholder {
    color: ${darkGrey};
    opacity: 1;
    font-weight: ${fontWeightNormal};
  }

  &:disabled,
  &[readonly] {
    background-color: ${white};
    border: 1px solid ${lightGrey};
    /* iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655. */
    opacity: 1;

    ${({ isError }) =>
      isError &&
      `
    border: 1px solid ${red};
  `}
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ isError }) =>
    isError &&
    `
    background-color: ${white};
    border: 1px solid ${red};
    color: ${red};
  `}
`;

export const FormGroup = styled.div`
  margin-bottom: ${rem(24)};
`;

export const FormGroupSplit = styled.div`
  display: flex;
  width: 100%;

  & > div {
    box-sizing: border-box;
    flex-basis: 50%;
    min-width: 50%;
  }

  & > div:first-child {
    padding-right: ${rem(10)};
  }
  & > div:last-child {
    padding-left: ${rem(10)};
  }
`;

export const FormGroupActions = styled(ButtonContainerStyled)`
  margin-top: ${rem(32)};
`;

export type InputProps = {
  isError?: boolean;
  isSmall?: boolean;
};
export const TextInput = styled.input.attrs(() => ({ type: "text" }))`
  ${formControlStyles}
`;
export const TelInput = styled.input.attrs(() => ({ type: "tel" }))`
  ${formControlStyles}
`;
export const DateInput = styled.input.attrs(() => ({ type: "date" }))`
  ${formControlStyles}
`;
export const MonthInput = styled.input.attrs(() => ({ type: "month" }))`
  ${formControlStyles}
  & ::-webkit-calendar-picker-indicator {
    margin-left: -32px;
    :hover {
      cursor: pointer;
    }
  }
`;
export const DateTimeInput = styled.input.attrs(() => ({ type: "datetime-local" }))`
  ${formControlStyles}
`;
export const SelectInput = styled.select`
  ${formControlStyles}

  // hide the default caret
  &::-ms-expand {
    opacity: 0;
  }

  appearance: none;
  // add custom caret
  background: url(${arrowDown});
  background-repeat: no-repeat;
  background-position: right ${rem(12)} center;
  background-size: ${({ isSmall }) => (isSmall ? rem(12) : rem(14))};
  background-color: ${softGrey};
  // fixes IE Edge padding that covers the custom arrow
  padding-right: ${({ isSmall }) => (isSmall ? rem(36) : rem(40))}; // 40 = 12 (padding) + 16 (svg size) + 12 (padding)
  &::-ms-value {
    // For visual consistency with other platforms/browsers,
    // supress the default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: ${darkGrey};
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const SearchInput = styled.input.attrs(() => ({ type: "search" }))`
  ${formControlStyles}
`;
export const EmailInput = styled.input.attrs(() => ({
  type: "email",
}))<InputProps>`
  ${formControlStyles}
`;

export const NumberInput = styled.input.attrs(() => ({ type: "number" }))`
  ${formControlStyles}
`;

export const MonetaryInput = styled(CurrencyInput)`
  ${formControlStyles}
`;

export const TextAreaInput = styled.textarea.attrs(({ rows = 3 }) => ({
  rows,
}))`
  ${formControlStyles};
  height: auto;
  padding: ${rem(16)};
  resize: vertical;
  &::placeholder {
    line-height: 1.5;
  }
`;

export const HiddenInput = styled.input.attrs(() => ({ type: "hidden" }))`
  ${formControlStyles}
`;

export const UrlInput = styled.input.attrs(() => ({ type: "url" }))`
  ${formControlStyles}
`;

export const InputDisplayOnly = styled(TextInput as any).attrs({
  tabIndex: -1,
  readOnly: true,
})`
  background-color: ${white};
`;

export const InputLabel = styled.label`
  color: ${darkGrey};
  display: block;
  font-weight: ${fontWeightNormal};
  margin-bottom: ${rem(4)};
  font-size: ${fontSize14};
  line-height: ${fontSize14LineHeight};
`;

const visuallyHiddenStyles = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const VisuallyHiddenLabel = styled.label`
  ${visuallyHiddenStyles}
`;
export const VisuallyHiddenLegend = styled.legend`
  ${visuallyHiddenStyles}
`;

export const InputPostfixStyled = styled.div`
  position: relative;
  input {
    padding-right: ${rem(56)};
  }
`;

export const InputPostfixIndicatorStyled = styled.div`
  border-left: 1px solid ${darkGrey};
  font-size: ${fontSize16};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${rem(40)};
  top: 0;
  bottom: 0;
  right: 0;
`;
