import styled, { css } from "styled-components";
import { mediaUp, rem } from "@mc/components/core/styles";
import { lightGrey, black, highlight, white } from "@mc/components/core/colours.styles";
import { PropertyCardProps } from "./property-card.styles";
import { Badge } from "@mc/components/badge/badge.styles";
import { fontSize14, H4, PStyled } from "@mc/components/core/typography.styles";
import Icon from "@mc/components/icons/Icon";

const baseCard = css`
  background-color: ${white};
  border-radius: ${rem(4)};
  border: 1px solid ${lightGrey};
  display: flex;
  padding: 0;
  justify-content: space-between;
  text-align: left;
`;

type CardRowProps = {
  isHighlighted?: boolean;
  isCompact: boolean;
};
export const CardRow = styled.li<CardRowProps>`
  ${baseCard}
  min-height: ${({ isCompact }) => rem(isCompact ? 128 : 240)};

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    border-color: ${highlight};
  `};

  ${PropertyCardProps} {
    display: none;
    ${mediaUp.sm`
      display: flex;
    `}
  }
`;

export const CardImageContainer = styled.div<{ isCompact: boolean }>`
  border-radius: ${rem(4)} 0 0 ${rem(4)};
  width: ${({ isCompact }) => (isCompact ? rem(128) : "50%")};
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const CardContent = styled.div<{ isCompact: boolean }>`
  flex: 1;
  padding: ${rem(16)};
  position: relative;
  overflow: hidden;
  width: ${({ isCompact }) => (isCompact ? rem(128) : "50%")};
  ${({ onClick }) =>
    onClick &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const CardDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rem(24)};

  ${Badge} {
    margin-right: ${rem(8)};
    text-decoration: none;
  }
`;

export const PropertyCardIcon = styled(Icon)`
  fill: ${black};
`;

export const PropertyCardPreHeading = styled(PStyled)`
  font-size: ${fontSize14};
  margin-bottom: ${rem(24)};
`;

export const TopRightActionContainer = styled.div`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
`;

export const LoadMoreImagesButton = styled.button`
  position: absolute;
  bottom: ${rem(8)};
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  padding: ${rem(6)} ${rem(16)};
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: ${rem(16)};
  border: none;
  font-size: ${fontSize14};
  color: ${white};
  cursor: pointer;
`;

export const AddressWrapper = styled(H4)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
