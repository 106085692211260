import styled from "styled-components";
import { baseFocusState, rem } from "../core/styles";
import { black, white, darkGrey } from "../core/colours.styles";
import { fontSize16, fontSize16LineHeight } from "../core/typography.styles";
import { SpacingValue } from "../core/spacing.styles";

export const CheckboxInput = styled.input.attrs(() => ({ type: "checkbox" }))`
  height: 0;
  width: 0;
  margin-top: ${rem(6)};
  margin-right: ${rem(12)};
  display: inline-block;
  opacity: 0;
  /* Fallback list of props depending on browser support */
  vertical-align: top;
  vertical-align: sub;
  vertical-align: text-bottom;

  & + span {
    font-size: ${fontSize16};
    padding-left: ${rem(16)};
  }

  & + span:before {
    position: absolute;
    content: "";
    display: inline-block;
    height: ${rem(16)};
    width: ${rem(16)};
    border: 1px solid ${black};
    left: 0;
    top: ${rem(2)};
  }

  &:checked + span:before {
    background: ${black};
    content: "";
  }
  &:checked:disabled + span:before {
    background: ${darkGrey};
    content: "";
  }
  &:checked + span:after {
    position: absolute;
    content: "";
    color: ${white};
    height: ${rem(3)};
    width: ${rem(9)};
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: ${rem(4)};
    top: ${rem(7)};
  }

  &:focus + span:before {
    ${baseFocusState};
  }

  &:checked:focus + span:before {
    border-color: ${white};
  }

  &:disabled + span {
    color: ${darkGrey};
    &:hover {
      cursor: not-allowed;
    }
  }
  &:disabled + span:before {
    border-color: ${darkGrey};
  }
`;

type CheckboxInputWrapperProps = {
  marginBottom?: SpacingValue;
};

export const CheckboxInputWrapper = styled.label<CheckboxInputWrapperProps>`
  display: flex;
  font-size: ${fontSize16};
  margin-bottom: ${({ marginBottom }) => rem(marginBottom ?? 16)};
  line-height: ${fontSize16LineHeight};
  position: relative;
`;
