import styled from "styled-components";
import { rem, standardFocusState } from "../core/styles";
import {
  fontWeightNormal,
  fontSize18,
  fontSize16,
  fontSize16LineHeight,
  fontWeightMedium,
} from "../core/typography.styles";
import { lightGrey, darkGrey, black, white } from "../core/colours.styles";

type AccordionStateProps = {
  isExpanded: boolean;
};

type AccordionContentStateProps = {
  isExpanded: boolean;
  $margin?: number;
  $paddingBottom?: number;
};

type AccordionHeadingProps = {
  $padding?: number;
};

export const AccordionStyled = styled.div<{ hasBorderTop?: boolean }>`
  border-top: ${({ hasBorderTop = true }) => (hasBorderTop ? "1px solid lightGrey" : "none")};
  position: relative;
`;

export const AccordionPanelStyled = styled.div<{ hasBorderBottom?: boolean }>`
  border-bottom: ${({ hasBorderBottom = true }) => (hasBorderBottom ? `1px solid ${lightGrey}` : "none")};
  box-sizing: border-box;
`;

export const AccordionContentStyled = styled.div<AccordionContentStateProps>`
  margin-left: ${({ $margin }) => ($margin ? rem($margin) : undefined)};
  margin-right: ${({ $margin }) => ($margin ? rem($margin) : undefined)};
  box-sizing: border-box;
  padding-bottom: ${({ $paddingBottom }) => rem($paddingBottom ?? 24)};

  &:focus {
    outline: 0;
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    max-height: 0;
    padding-bottom: 0;
    padding-top: 0;
    overflow: hidden;
    display: none;
  `}

  ${({ isExpanded }) =>
    isExpanded &&
    `
    display: ${isExpanded ? "block" : "none"};
  `}
`;

export const AccordionLabelStyled = styled.span`
  line-height: ${rem(26)};
  flex: 0 0 90%;
  font-size: ${fontSize18};
`;

export const AccordionHeadingStyled = styled.h2<AccordionHeadingProps>`
  padding-left: ${({ $padding }) => ($padding ? rem($padding) : undefined)};
  margin: 0;
  position: relative;
`;

type AccordionButtonProps = {
  noLayout?: boolean;
};
export const AccordionButtonStyled = styled.div<AccordionButtonProps>`
  background: none;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: ${fontSize18};
  font-weight: ${fontWeightNormal};
  padding: ${rem(16)} ${rem(48)} ${rem(16)} 0;
  position: relative;
  width: 100%;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
  ${standardFocusState};
`;

export const AccordionButtonIconStyled = styled.div<AccordionStateProps>`
  bottom: auto;
  margin-top: -${rem(20)};
  right: ${rem(10)};
  position: absolute;
  top: 50%;

  svg {
    fill: ${black};
    stroke: none;
    transition: transform 0.2s;
    transform: rotate(360deg);
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`;

export const AccordionSurfaceCardStyled = styled.div`
  & > ${AccordionStyled} {
    background-color: ${white};
    border: 1px solid ${darkGrey};
    border-radius: ${rem(8)};
    margin-bottom: ${rem(24)};
    padding: 0;

    & > ${AccordionPanelStyled} {
      border-radius: 0;
      border-bottom: 1px solid ${darkGrey};
      margin-bottom: 0;
      padding: 0;

      &:last-child {
        border-bottom: none;
      }
    }

    ${AccordionContentStyled} {
      padding: ${rem(24)};
    }

    ${AccordionButtonStyled} {
      padding-left: ${rem(24)};
    }

    ${AccordionLabelStyled} {
      font-size: ${fontSize16};
      line-height: ${fontSize16LineHeight};
      font-weight: ${fontWeightMedium};
    }
  }
`;
