import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { baseFocusState, rem } from "@mc/components/core/styles";
import { formControlStyles } from "@mc/components/inputs/inputs.styles";
import { white } from "../core/colours.styles";

type Props = {
  prefix?: string;
};

export const MonetaryInputStyled = styled(CurrencyInput)`
  ${formControlStyles}
`;

export const MontaryInputWrapperStyled = styled.div<Props>`
  position: relative;

  ${({ prefix }) =>
    prefix &&
    `
    ${MonetaryInputStyled} {
      padding-left: ${rem(24)};
    }

    &:focus {
      ${baseFocusState};
      background-color: ${white};
    }

    &:before {
      content: "${prefix}";
      font-size: inherit;
      display: block;
      position: absolute;
      left: ${rem(10)};
      top: ${rem(10)};
    }
  `}
`;
